import { createStore } from 'vuex';
import axios from 'axios';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isMobile: window.innerWidth <= 768,
    isMenuOpen: false,

    user: null,
    balance : null,

    jwt: null,
    loginError: null,

    settings: null,
    games : {
      1: [], // 카지노
      2: [], // 슬롯
      3: [], // 스포츠
      4: [], // 파워볼 
      5: [] // 사다리
    },

    
    gameModalOpen: false,
    gameModalTab: 'liveCasino',

    infoModalOpen: false,
    infoModalTab: 'money',

    loginModalOpen : false,
    loginModalTab: 'login',
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    toggleMenu(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
    closeMenu(state) {
      state.isMenuOpen = false;
    },
    setUser(state, user) {
      state.user = user;
    },
    setBalance(state, amount){
      state.balance = amount
    },
    setLoginError(state, error) {
      state.loginError = error;
    },
    setJwt(state, jwt) {
      state.jwt = jwt;
    },
    logout(state) {
      state.user = null;
      state.jwt = null;
      localStorage.removeItem('jwt');
    },
    SET_GAME_MODAL_OPEN(state, isOpen) {
      state.gameModalOpen = isOpen;
    },
    SET_GAME_MODAL_TAB(state, tab) {
      state.gameModalTab = tab;
    },
    SET_GAME_LIST(state, gameList) {
      state.gameList = gameList;
    },
    SET_CATEGORIZED_DATA(state, categorizedData) {
      state.categorizedData = categorizedData;
    },
    SET_INFO_MODAL_OPEN(state, isOpen) {
      state.infoModalOpen = isOpen;
    },
    SET_INFO_MODAL_TAB(state, tab) {
      state.infoModalTab = tab;
    },
    SET_LOGIN_MODAL_OPEN(state, isOpen) {
      state.loginModalOpen = isOpen;
    },
    SET_LOGIN_MODAL_TAB(state, tab) {
      state.loginModalTab = tab;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
    setCategorySports(state, categorySports) {
      state.categorySports = categorySports;
    },
    setGames(state, gameData) {
      state.games = gameData;
    },
    
  },
  actions: {
    checkMobile({ commit }) {
      const isMobile = window.innerWidth <= 768;
      commit('setIsMobile', isMobile);
    },
    toggleMenu({ commit }) {
      commit('toggleMenu');
    },
    closeMenu({ commit }) {
      commit('closeMenu');
    },

    closeInfoModal({ commit }) {
      commit('SET_INFO_MODAL_OPEN',false);
    },

    openGameModal({ commit }, tab) {
      commit('SET_GAME_MODAL_TAB', tab);
      commit('SET_GAME_MODAL_OPEN', true);
    },
    openInfoModal({ commit }, tab) {
      commit('SET_INFO_MODAL_TAB', tab);
      commit('SET_INFO_MODAL_OPEN', true);
    },
    openLoginModal({ commit }, tab) {
      commit('SET_LOGIN_MODAL_TAB', tab);
      commit('SET_LOGIN_MODAL_OPEN', true);
    },

    async login({ commit }, { identifier, password }) {
      try {
        const HEADERS = {
          "Content-Type": "application/json",
        };
        const response = await axios.post(`https://goldfish-app-cklru.ondigitalocean.app/api/auth/local`, {
          identifier,
          password,
        }, { headers: HEADERS });
        const { jwt, user } = response.data;
        localStorage.setItem('jwt', jwt);
        commit('setJwt', jwt);
        commit('setUser', user);
        commit('setLoginError', null);
        commit('SET_LOGIN_MODAL_OPEN', false);
      } catch (error) {
        commit('setLoginError', error.response ? error.response.data.message : 'Network Error');
      }
    },
    async logout({ commit, state }) {
      const URL = `https://goldfish-app-cklru.ondigitalocean.app/api/auth/logout`;
      try {
        let jwt = state.jwt
        commit('setUser', null);
        commit('setJwt', null);
        localStorage.removeItem('jwt');
        await axios({
          method: "GET",
          url: URL,
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        // 서버 로그아웃 성공 후 로컬 상태 정리
        
        console.log('로그아웃 성공');
      } catch (error) {
        console.error('로그아웃 실패:', error.response ? error.response.data : error.message);
        // 에러 처리 로직 (예: 사용자에게 알림)
      }
    },

    async fetchSettings({ commit }) {
      try {
        const response = await axios.get(`https://goldfish-app-cklru.ondigitalocean.app/api/setting`);
        commit('SET_SETTINGS', response.data.data.attributes);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    },

    async fetchGameList({ commit, state }) {
      try {
        const response = await axios({
          method: "GET",
          url: `https://goldfish-app-cklru.ondigitalocean.app/api/games?pagination[pageSize]=100&pagination[page]=1`,
          headers: {
            Authorization: `Bearer ${state.jwt}`,
          },
        })

        let data = {
          1: [], // 카지노
          2: [], // 슬롯
          3: [], // 스포츠
          4: [], // 파워볼 
          5: [] // 사다리
        }
        response.data.data.forEach(game => {
            if (game.attributes.category in data) {
              data[game.attributes.category].push(game)
            }
        });

        console.log(data)
        commit('setGames',data)
      } catch (error) {
        console.error('Failed to fetch game list:', error);
      }
    },

    






    async getSports({ commit }) {
      try {
        const response = await axios.get('https://goldfish-app-cklru.ondigitalocean.app/api/sports');
        console.log(response.data);
        console.log(commit);
      } catch (error) {
        console.error('Failed to fetch sports:', error);
      }
    },
    async getCategorySports({ commit }) {
      try {
        const response = await axios.get(`https://goldfish-app-cklru.ondigitalocean.app/api/category-sports/list`);
        commit('setCategorySports', response.data);
      } catch (error) {
        console.error('Failed to fetch category sports:', error);
      }
    },
    
    
  },
  getters: {

    getSettings: (state) => state.settings,
    getGames: (state) => (category) => {
      return state.games[category] || [];
    }
  },
  plugins: [
    createPersistedState({
      paths: [
        'user',
        'jwt'
      ]
    }),
  ],
});
