<template>
  <div>
    <header class="header bg-dark text-white w-100">
      <div v-if="isMobile" class="container-fluid py-2">
        <div class="row align-items-center justify-content-between">
          <div class="col-6 d-flex align-items-center">
            <a class="navbar-brand text-white" href="/" >
              <img src="@/assets/logo.png" alt="Logo" class="logo">
            </a>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">
            <button class="btn btn-outline-light" type="button" @click="toggleMenu">
              <img :src="menuIcon" alt="Menu Icon" width="24" height="24">
            </button>
          </div>
        </div>
        <div v-if="isMenuOpen" class="row mt-2">
          <div class="col-12">
            <div v-if="!user" class="d-flex flex-column mb-3 mt-2">
              <button class="btn login-btn mb-3" type="button" @click="openLoginModal('login')">로그인</button>
              <button class="btn signup-btn" type="button" @click="openLoginModal('register')">회원가입</button>
            </div>
            <ul class="navbar-nav">
              <li class="nav-item"><a class="nav-link" @click="openGameModal('liveCasino')">라이브카지노</a></li>
              <li class="nav-item"><a class="nav-link" @click="openGameModal('slot')">슬롯</a></li>
              <li class="nav-item"><a class="nav-link" @click="openGameModal('sports')">스포츠</a></li>
              <li class="nav-item"><a class="nav-link" @click="openGameModal('miniGame')">미니게임</a></li>
              <li class="nav-item"><a class="nav-link" @click="openInfoModal('deposit')">입금신청</a></li>
              <li class="nav-item"><a class="nav-link" @click="openInfoModal('withdraw')">출금신청</a></li>
              <li class="nav-item"><a class="nav-link" @click="openInfoModal('money')">고객센터</a></li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  한국어
                </a>
                <ul class="dropdown-menu" aria-labelledby="languageDropdown">
                  <li><a class="dropdown-item" href="#">English</a></li>
                  <li><a class="dropdown-item" href="#">한국어</a></li>
                </ul>
              </li>
            </ul>
            <div v-if="user" class="d-flex flex-column mt-3">
              <span class="text-white mb-2">환영합니다, {{ user.name }}</span>
              <button class="btn logout-btn mb-2" type="button" @click="logout">로그아웃</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="container py-2">
        <div class="row align-items-center">
          <div class="col-auto">
            <a class="navbar-brand d-flex align-items-center text-white" href="/">
              <img src="@/assets/logo.png" alt="Logo" class="logo">
            </a>
          </div>
          <div class="col">
            <div class="d-flex justify-content-end align-items-center">
              <div v-if="!user" class="d-flex align-items-center">
                <button class="btn login-btn mx-2" type="button" @click="openLoginModal('login')">로그인</button>
              <button class="btn signup-btn" type="button" @click="openLoginModal('register')">회원가입</button>
              </div>
              <div v-else class="d-flex align-items-center user-info">
                <div class="user-avatar me-2">
                  <img src="@/assets/img/default.jpg" alt="User Avatar" class="rounded-circle">
                </div>
                <div class="user-details me-3">
                  <span class="username" @click="openInfoModal('passwordchange')">{{ user.nickname }} 님</span>
                  <div class="user-stats" @click="openInfoModal('exchange')">
                    <span class="stat-item">{{ formatCurrency(store.state.balance) }}</span>
                    <span class="stat-item">P {{ formatNumber(0) }}</span>
                    <span class="stat-item">C {{ formatNumber(10000) }}</span>
                    <span class="stat-item"><i class="fas fa-trophy"></i> {{ user.level }}</span>
                    <!-- {{store.state.user}} -->
                  </div>
                </div>
                <button class="btn logout-btn" type="button" @click="logout">
                  <i class="fas fa-sign-out-alt"></i> 로그아웃
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <nav v-if="!isMobile" class="navbar navbar-expand-lg navbar-dark bg-dark w-100 mt-2">
        <div class="container">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item"><a class="nav-link" href="#" @click="openGameModal('liveCasino')">라이브카지노</a></li>
            <li class="nav-item"><a class="nav-link" href="#" @click="openGameModal('slot')">슬롯</a></li>
            <li class="nav-item"><a class="nav-link" href="#" @click="openGameModal('sports')">스포츠</a></li>
            <li class="nav-item"><a class="nav-link" href="#" @click="openGameModal('miniGame')">미니게임</a></li>
          </ul>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <!-- <li class="nav-item"><a class="nav-link" href="#" @click="openInfoModal('money')">프로모션</a></li> -->
            <li class="nav-item"><a class="nav-link" href="#" @click="openInfoModal('deposit')">입금신청</a></li>
            <li class="nav-item"><a class="nav-link" href="#" @click="openInfoModal('withdraw')">출금신청</a></li>
            <li class="nav-item"><a class="nav-link" href="#">고객센터</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                한국어
              </a>
              <ul class="dropdown-menu" aria-labelledby="languageDropdown">
                <li><a class="dropdown-item" href="#">English</a></li>
                <li><a class="dropdown-item" href="#">한국어</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
    <!-- 모달 컴포넌트 -->
    <GameModal :selected-tab="gameModalTab" :tabs="tabs" v-if="gameModalOpen" />
    <InfoModal :selected-tab="infoModalTab" :tabs="tabs" v-if="infoModalOpen" />
    <LoginModal :selected-tab="loginModalTab" :tabs="tabs" v-if="loginModalOpen"/>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import GameModal from '@/modal/GameModal.vue';
import InfoModal from '@/modal/InfoModal.vue';
import LoginModal from '@/modal/LoginModal.vue';
import menuIcon from '@/assets/menu-icon.svg';

export default {
  components: {
    GameModal,
    InfoModal,
    LoginModal,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.state.user);
    const isMobile = computed(() => store.state.isMobile);
    const isMenuOpen = computed(() => store.state.isMenuOpen);
    const selectedTab = ref('liveCasino');
    const tabs = [
      { name: '라이브카지노', value: 'liveCasino' },
      { name: '슬롯', value: 'slot' },
      { name: '스포츠', value: 'sports' },
      { name: '미니게임', value: 'miniGame' }
    ];

    const toggleMenu = () => {
      store.dispatch('toggleMenu');
    };

    const checkMobile = () => {
      store.dispatch('checkMobile');
    };

    const logout = () => {
      store.dispatch('logout');
    };

    const gameModalOpen = computed(() => store.state.gameModalOpen);
    const gameModalTab = computed(() => store.state.gameModalTab);
    
    const infoModalOpen = computed(() => store.state.infoModalOpen);
    const infoModalTab = computed(() => store.state.infoModalTab);

    const loginModalOpen = computed(() => store.state.loginModalOpen);
    const loginModalTab = computed(() => store.state.loginModalTab);

    const openGameModal = (tab) => {
      store.commit('SET_GAME_MODAL_TAB', tab);
      store.commit('SET_GAME_MODAL_OPEN', true);
    };
    
    const openInfoModal = (tab) => {
      store.commit('SET_INFO_MODAL_TAB', tab);
      store.commit('SET_INFO_MODAL_OPEN', true);
    };

    const openLoginModal = (tab) => {
      store.commit('SET_LOGIN_MODAL_TAB', tab);
      store.commit('SET_LOGIN_MODAL_OPEN', true);
    };


    const updateSelectedTab = (tab) => {
      selectedTab.value = tab;
    };

    const formatCurrency = (value) => {
      return new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(value);
    };

    const formatNumber = (value) => {
      return new Intl.NumberFormat('ko-KR').format(value);
    };

    onMounted(() => {
      checkMobile();
      window.addEventListener('resize', checkMobile);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', checkMobile);
    });

    return {
      user,
      isMobile,
      isMenuOpen,
      selectedTab,
      tabs,
      toggleMenu,
      logout,

      gameModalOpen,
      infoModalOpen,
      loginModalOpen,

      gameModalTab,
      infoModalTab,
      loginModalTab,

      openGameModal,
      openInfoModal,
      openLoginModal,

      updateSelectedTab,
      menuIcon,
      store,
      formatCurrency,
      formatNumber,
    };
  }
};
</script>

<style scoped>
.header {
  background: linear-gradient(to right, #2e272a, #000000);
  /* padding: 1rem 0; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.navbar {
  background-color: rgba(10, 10, 10, 0.7);
  padding: 1rem 0;
  border-top: 1px solid #2a2a2a;
}

.navbar-nav .nav-item {
  position: relative;
  margin-right: 1rem;
}

.navbar-nav .nav-link {
  color: #e0e0e0 !important;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link:focus {
  color: #ffd700 !important;
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-nav .nav-item::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -5px;
  left: 50%;
  background-color: #4a0c3c;
  transition: all 0.3s ease;
}

.navbar-nav .nav-item:hover::after {
  width: 100%;
  left: 0;
}

.navbar-nav .dropdown-menu {
  background-color: rgba(20, 20, 20, 0.9);
  border: 1px solid #444;
}

.navbar-nav .dropdown-item {
  color: #e0e0e0;
  transition: all 0.3s ease;
}

.navbar-nav .dropdown-item:hover,
.navbar-nav .dropdown-item:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffd700;
}
.logo {
  width: 200px;
  height: auto;
  margin-right: 10px;
}
.input-field {
  width: 150px;
  background-color: #222; /* Darker background for input fields */
  border: 1px solid #444;
  color: #fff;
  padding: 0.5rem;
}
.input-field-full {
  width: 100%;
  background-color: #222; /* Same darker background for full-width input fields */
  border: 1px solid #444;
  color: #fff;
  padding: 0.5rem;
}
.btn {
  padding: 0.5rem 1rem;
  border: none;
}
.login-btn, .logout-btn {
  background-color: #3a0647;
  color: #e0e0e0;
  border: 1px solid #6a0dad;
  transition: all 0.3s ease;
}

.login-btn:hover, .logout-btn:hover {
  background-color: #6a0dad;
  color: #ffffff;
}

.signup-btn {
  background-color: #6a0dad;
  color: #ffffff;
  border: 1px solid #9b59b6;
  transition: all 0.3s ease;
}

.signup-btn:hover {
  background-color: #9b59b6;
  color: #ffffff;
}
.navbar {
  background-color: #111; /* Slightly lighter black for the navbar */
  padding: 1rem 0;
}
.nav-link {
  color: #fff !important;
  margin-right: 1rem;
}
.nav-link:hover {
  color: #ccc !important; /* Lighter gray on hover */
}
.dropdown-menu {
  background-color: #000; /* Deep black background for dropdown */
  border: 1px solid #444; /* Dark gray border */
}
.dropdown-item {
  color: #fff; /* White text */
}
.dropdown-item:hover {
  background-color: #222; /* Darker gray on hover */
  color: #fff;
}
.user-info {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.user-avatar img {
  width: 40px;
  height: 40px;
  border: 2px solid #6a0dad;
}

.user-details {
  display: flex;
  flex-direction: column;
}

.username {
  font-weight: bold;
  color: #ffefa6;
  font-size: 1.1rem;
  cursor: pointer;
}

.user-stats {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.stat-item {
  font-size: 0.9rem;
  color: #e0e0e0;
}

.stat-item i {
  margin-right: 0.3rem;
  color: #9b59b6;
}

.logout-btn {
  background-color: #3a0647;
  color: #e0e0e0;
  border: 1px solid #6a0dad;
  transition: all 0.3s ease;
  padding: 0.3rem 0.8rem;
  font-size: 0.9rem;
}

.logout-btn:hover {
  background-color: #6a0dad;
  color: #ffffff;
}

.logout-btn i {
  margin-right: 0.3rem;
}
</style>
