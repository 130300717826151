import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

const app = createApp(App)

// Store와 Router를 사용하도록 설정
app.use(store)
app.use(router)

// 앱을 바로 마운트
app.mount('#app')