// 은행명
export const BANK = {
  // TODO: 증권사 추가
  1: "경남",
  2: "광주",
  3: "국민",
  4: "기업",
  5: "농협",
  6: "대구",
  7: "부산",
  8: "산림",
  9: "산업",
  10: "새마을",
  11: "수협",
  12: "신한",
  13: "신협",
  14: "씨티",
  15: "우리",
  16: "우체국",
  17: "저축",
  18: "전북",
  19: "제주",
  20: "카카오",
  21: "케이",
  22: "토스",
  23: "하나",
  24: "SC",
};

// 통신사
export const CARRIER = {
  1: "SK",
  2: "KT",
  3: "LG",
  4: "알뜰",
};

// 성별
export const GENDER = {
  1: "남성",
  2: "여성",
};

// 팝업탭
export const TAB = {
  회원정보: "/popup/user",
  문의: "/popup/msg-user",
  쪽지: "/popup/msg-admin-reader",
  머니: "/popup/money",
  포인트: "/popup/point",
  콤프: "/popup/comp",
  쿠폰머니: "/popup/couponmoney",
  쿠폰: "/popup/coupon",
  롤링: "/popup/rolling",
  베팅: "/popup/betting",
  베팅설정: "/popup/betting-setting",
  요율설정: "/popup/commission-rate-setting",
  회원등록: "/popup/register",
  회원로그: "/popup/log-admin",
  롤링설정: "/popup/commission-setting", // TODO: 커미션셋팅(롤링및콤프지급조건)의 라인별
  베팅설정N: "/popup/rolling-setting", // TODO: 베팅셋팅의 라인별
};

// USER
export const USER = {
  TYPE: { 1: "일반", 2: "보험" },
  STATE: {
    1: "대기",
    2: "정상",
    3: "중지",
    4: "탈퇴",
  },
  GRADE: {
    1: "회원",
    2: "매장",
    3: "총판",
    4: "본사",
    5: "총본사",
    6: "운영사",
  },
  FIELD: ["type", "state", "username", "nickname", "uuid", "grade"],
};

// USERINFO
export const USERINFO = {
  GRANT: {
    is_enable_give_money: "머니지급 가능",
    is_enable_take_money: "머니회수 가능",
    is_disable_change_point: "포인트전환 불가",
    is_disable_change_comp: "콤프전환 불가",
    is_enable_issue_coupon: "쿠폰발행 가능",
  },
  FIELD: ["bank", "depositor", "account"],
};

// MSG-ADMIN-SENDER
export const MSG_ADMIN_SENDER = {
  STATE: {
    0: "전체(삭제 포함)",
    1: "임시저장",
    2: "발신완료",
    3: "삭제",
  },
  IS_MANDATORY: { false: "일반", true: "필독" },
  TARGET: {
    0: "개별",
    1: "회원",
    2: "매장",
    3: "총판",
    4: "본사",
    5: "총본사",
    6: "운영사",
    7: "접속 회원",
    8: "모든 회원",
  },
};

// MSG-ADMIN-READER
export const MSG_ADMIN_READER = {
  STATE: { 0: "전체(삭제 포함)", 1: "안읽음", 2: "읽음", 3: "삭제" },
};

// IP-BLOCK
export const IP_BLOCK = {
  TYPE: { 1: "운영자", 2: "시스템" },
  STATE: { 0: "전체(삭제 포함)", 1: "정상", 2: "삭제" },
};

// LOG-ADMIN
export const LOG_ADMIN = {
  TYPE: { 1: "생성", 2: "수정", 3: "삭제", 4: "기타" },
  LOCATION: {
    1: "회원가입",
    2: "로그인",
    3: "로그아웃",
    4: "비밀번호 변경",
    5: "회원정보",
    6: "이벤트 설정",
    7: "레벨 설정",
    8: "게임 설정",
    9: "베팅 설정",
    10: "배당률 설정",
    11: "콤프 설정",
    12: "롤링 및 콤프 설정",
  },
  RESULT: { true: "성공", false: "실패" },
};

// MONEY
export const MONEY = {
  TABLE: {
    books: "머니",
    points: "포인트",
    comps: "콤프",
    couponmonies: "쿠폰머니",
  },
  TYPE: {
    1: "입금",
    2: "출금",
    3: "관리자 지급",
    4: "관리자 회수",
    5: "머니 지급",
    6: "머니 회수",
    7: "머니 지급받음",
    8: "머니 회수당함",
    9: "게임머니 지급",
    10: "게임머니 회수",
  },
  STATE: {
    1: "신청",
    2: "대기",
    3: "완료",
    4: "취소",
  },
  POINT_MEMO: ["가입 첫충", "매일 첫충", "매충", "없음"],
};

// EVENT-SETTING
export const EVENT_SETTING = {
  TYPE: {
    1: "가입 첫충",
    2: "매일 첫충",
    3: "매충",
  },
};

// COUPON-SETTING
export const COUPON_SETTING = {
  TYPE: {
    1: "관리자",
    2: "파트너",
  },
  STATE_CREATE: {
    0: "전체(삭제 포함)",
    1: "발행가능",
    2: "발행불가",
    3: "삭제",
  },
  IS_REQUIRE_CONFIRM: {
    true: "필요",
    false: "불필요",
  },
  IS_FIX_EXPIRE: {
    true: "고정",
    false: "비고정",
  },
};

// COUPON
export const COUPON = {
  STATE_ISSUE: {
    1: "사용가능",
    2: "사용요청",
    3: "사용완료",
    4: "회수",
    5: "만료",
  },
};

// MSG-USER
export const MSG_USER = {
  TYPE: { 1: "일반", 2: "파트너", 3: "입출금" },
  FEED_STATE: { 0: "전체(삭제 포함)", 1: "대기", 2: "답변", 3: "삭제" },
};

// BOARD
export const BOARD = {
  TYPE: { 1: "공지사항", 2: "이벤트", 3: "팝업", 4: "이용규정" },
  STATE: { 0: "전체(삭제 포함)", 1: "대기", 2: "게시", 3: "삭제" },
  IS_SHOW_PUBLIC: {
    true: "공개",
    false: "비공개",
  },
  IS_SHOW_DATE: {
    true: "공개",
    false: "비공개",
  },
};

// BOOKMAKR
export const BOOKMARK = {
  TYPE: { 1: "쪽지", 2: "문의" },
  STATE: { 0: "전체(삭제 포함)", 1: "정상", 2: "삭제" },
};

// LEVEL-SETTING
export const LEVEL_SETTING = {
  TYPE: { 1: "회원레벨", 2: "게임레벨", 3: "콤프레벨" },
};

// GAME-SETTING
export const GAME_SETTING = {
  CATEGORY: {
    1: "카지노",
    2: "슬롯",
    3: "스포츠",
    4: "파워볼",
    5: "파워사다리",
    6: "스피드키노",
    7: "런닝볼스피드",
    8: "런닝볼메이즈",
  },
  POOL: {
    SINGLE: "단폴",
    DOUBLE: "다폴",
    POWERBALL_SINGLE_ODDEVEN: "파워볼 홀짝",
    POWERBALL_SINGLE_UNDEROVER: "파워볼 언더오버",
    NORMALBALL_SINGLE_ODDEVEN: "일반볼 홀짝",
    NORMALBALL_SINGLE_UNDEROVER: "일반볼 언더오버",
    NORMALBALL_SINGLE_LMS: "일반볼 대중소",
    LADDER_SINGLE_LEFTRIGHT: "좌우",
    LADDER_SINGLE_34: "34",
    LADDER_SINGLE_ODDEVEN: "홀짝",
    KENO_SINGLE_ODDEVEN: "홀짝",
    KENO_SINGLE_UNDEROVER: "언더오버",
    SPEED_SINGLE_NUMBER: "숫자",
    SPEED_SINGLE_1_ODDEVEN: "	1등 홀짝",
    SPEED_SINGLE_1_UNDEROVER: "1등 언더오버",
    SPEED_SINGLE_123_ODDEVEN: "123등 홀짝",
    SPEED_SINGLE_123_UNDEROVER: "	123등 언더오버",
    MAZE_SINGLE_NUMBER: "숫자",
    MAZE_SINGLE_ODDEVEN: "홀짝",
  },
};

// TODO: MINIGAME
export const MINIGAME = {
  GAME_LIST: { "eos-powerballs": "EOS파워볼", "coin-ladders": "코인사다리" },
  GAME_ID: { 1: "3분", 2: "5분" },
};

// TODO: GAME-RATE-SETTING
export const GAME_RATE_SETTING = {
  TYPE: { rate_a: "TYPE A", rate_b: "TYPE B" },
  POOL: {
    POWERBALL_SINGLE_ODDEVEN: ["홀짝"],
    POWERBALL_SINGLE_UNDEROVER: ["언더오버"],
    NORMALBALL_SINGLE_ODDEVEN: ["홀짝"],
    NORMALBALL_SINGLE_UNDEROVER: ["언더오버"],
    NORMALBALL_SINGLE_LMS: ["대소", "중"],
    LADDER_SINGLE_LEFTRIGHT: ["좌우"],
    LADDER_SINGLE_34: ["34"],
    LADDER_SINGLE_ODDEVEN: ["홀짝"],
    KENO_SINGLE_ODDEVEN: ["홀짝"],
    KENO_SINGLE_UNDEROVER: ["언더오버"],
    SPEED_SINGLE_NUMBER: ["숫자"],
    SPEED_SINGLE_1_ODDEVEN: ["	홀짝"],
    SPEED_SINGLE_1_UNDEROVER: ["언더오버"],
    SPEED_SINGLE_123_ODDEVEN: ["홀짝"],
    SPEED_SINGLE_123_UNDEROVER: ["언더오버"],
    MAZE_SINGLE_NUMBER: ["숫자"],
    MAZE_SINGLE_ODDEVEN: ["홀짝"],
  },
};

// TODO: MINIGAME
export const COMMISSION_SETTING = {
  TYPE: { is_give_rolling: "롤링", is_give_comp: "콤프" },
};
