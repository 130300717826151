<template>

  <div class="table">
    <div class="row header">
      <span class="cell league">리그</span>
      <span class="cell win-draw-lose">
        <span class="sub-header win">승</span>
        <span class="sub-header draw">무</span>
        <span class="sub-header lose">패</span>
      </span>
      <span class="cell handicap">핸디캡</span>
      <span class="cell over-under">오버언더</span>
      <span class="cell more">더보기</span>
    </div>
    <div v-for="row in data" :key="row.id" class="row">
      <span class="cell league">
        <div class="league-cell">
          <span class="date">{{ formatDate(row.date) }}</span>
          <div class="teams">
            <span class="team-name">{{row.homeTeam}}</span>
            <span class="away-name">{{row.awayTeam}}</span>
          </div>
        </div>
      </span>
      <span class="cell win-draw-lose">
        <div class="split-cell">
          <button 
            v-for="(value, key) in row.winDrawLose" 
            :key="key" 
            class="split-item"
            :class="{ 'selected': isSelected(row.id, 'winDrawLose', key) }"
            @click="toggleSelection(row.id, 'winDrawLose', key)"
          >
            <div class="split-value">{{ value }}</div>
          </button>
        </div>
      </span>
      <span class="cell handicap">
        <div class="split-cell">
          <button 
            v-for="(value, key) in row.handicap" 
            :key="key" 
            class="split-item"
            :class="{ 'selected': isSelected(row.id, 'handicap', key) }"
            @click="toggleSelection(row.id, 'handicap', key)"
          >
            <div class="split-label">{{ key }}</div>
            <div class="split-value">{{ value }}</div>
          </button>
        </div>
      </span>
      <span class="cell over-under">
        <div class="split-cell">
          <button 
            v-for="(value, key) in row.overUnder" 
            :key="key" 
            class="split-item"
            :class="{ 'selected': isSelected(row.id, 'overUnder', key) }"
            @click="toggleSelection(row.id, 'overUnder', key)"
          >
            <div class="split-label">{{ key }}</div>
            <div class="split-value">{{ value }}</div>
          </button>
        </div>
      </span>
      <span class="cell more">
        {{ row.more }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'

export default {
    name: 'SportTest',
    setup() {
        const data = reactive([
        { 
            id: 1,
            league: 'EPL',
            date: '2024-08-26T14:30:00',
            homeTeam: '맨시티', 
            awayTeam: '리버풀', 
            winDrawLose: { 승: '1.90', 무: '3.50', 패: '3.80' },
            handicap: { 오버: '-1.5', 언더: '+1.5' }, 
            overUnder: { 오버: '2.5', 언더: '2.5' }, 
            more: '100+'
        },
        { 
            id: 2,
            league: 'LaLiga',
            date: '2024-08-27T20:00:00',
            homeTeam: '바르셀로나', 
            awayTeam: '레알마드리드', 
            winDrawLose: { 승: '2.10', 무: '3.30', 패: '3.40' },
            handicap: { 오버: '0', 언더: '0' }, 
            overUnder: { 오버: '3', 언더: '3' }, 
            more: '200+'
        },
        { 
            id: 3,
            league: 'Bundesliga',
            date: '2024-08-28T18:45:00',
            homeTeam: '바이에른', 
            awayTeam: '도르트문트', 
            winDrawLose: { 승: '1.70', 무: '3.80', 패: '4.50' },
            handicap: { 오버: '-1', 언더: '+1' }, 
            overUnder: { 오버: '3.5', 언더: '3.5' }, 
            more: '150+'
        },
        ])

        const selectedCells = ref({})

        const formatDate = (dateString) => {
        const date = new Date(dateString)
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        return `${month}/${day}\n${hours}:${minutes}`
        }

        const toggleSelection = (rowId, category, key) => {
        const cellId = `${rowId}-${category}-${key}`
        selectedCells.value[cellId] = !selectedCells.value[cellId]
        }

        const isSelected = (rowId, category, key) => {
        const cellId = `${rowId}-${category}-${key}`
        return selectedCells.value[cellId] || false
        }

        return {
        data,
        formatDate,
        toggleSelection,
        isSelected
        }
    }
}
</script>





<style scoped>
.table > :not(caption) > * > * {
  padding: 0 !important;
}

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  font-family: monospace;
  width: 100%;
  max-width: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  box-sizing: border-box;
  overflow-x: hidden;
}

.row {
  display: flex;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  width: 100%;
  box-sizing: border-box;
}

.header {
  background-color: #f2f2f2;
  font-weight: bold;
}

.cell {
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  box-sizing: border-box;
}

.league { width: 30%; }
.win-draw-lose, .handicap, .over-under { width: 20%; }
.more { width: 10%; }

.league-cell, .split-cell {
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  box-sizing: border-box;
}

.league-cell .date {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
  white-space: pre-line;
}

.league-cell .teams {
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.league-cell .team-name,
.league-cell .away-name {
  width: 100%;
  padding: 4px 0;
}

.league-cell .team-name {
  border-bottom: 1px solid #ddd;
}

.win-draw-lose {
  display: flex;
  padding: 0 !important;
}

.sub-header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ddd;
}

.sub-header:last-child {
  border-right: none;
}

.split-cell {
  flex-direction: row;
  height: 100%;
}

.split-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
}

.split-item:not(:last-child) {
  border-right: 1px solid #ddd;
}

.split-item:hover {
  background-color: #f0f0f0;
}

.split-item.selected {
  background-color: #e6f7ff;
  font-weight: bold;
}

.split-label {
  font-size: 0.8em;
  color: #666;
}

.split-value {
  font-weight: bold;
}

.row:nth-child(even) {
  background-color: #f8f8f8;
}

.row:hover {
  background-color: #ddd;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    width: 100%;
  }
  
  .cell {
    width: 100% !important;
    border-bottom: none;
    box-sizing: border-box;
  }
  
  .cell:last-child {
    border-bottom: 1px solid #ddd;
  }

  .league-cell {
    flex-direction: column;
  }

  .league-cell .date {
    border-right: none;
    border-bottom: 1px solid #ddd;
  }
  
  .league-cell .teams {
    flex-direction: row;
  }
  
  .league-cell .team-name,
  .league-cell .away-name {
    width: 50%;
    border-bottom: none;
  }
  
  .league-cell .team-name {
    border-right: 1px solid #ddd;
  }

  .split-cell {
    flex-direction: row;
  }

  .split-item:not(:last-child) {
    border-right: 1px solid #ddd;
  }
}
</style>



