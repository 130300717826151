<template>
  <main class="main-content text-white">
    <div class="container py-4 full-bg">
      <!-- 게임목록 섹션 -->
      <div class="row">
        <div class="col-md-4 mb-4">
          <div class="card modern-card">
            <div class="card-body">
              <div class="card-icon">
                <i class="fas fa-dice"></i>
              </div>
              <h5 class="card-title">라이브카지노</h5>
              <p class="card-text">실시간으로 라이브 딜러와 함께 게임을 즐기세요.</p>
              <button class="btn modern-btn" @click="openGameModal('liveCasino')">지금 시작</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card modern-card">
            <div class="card-body">
              <div class="card-icon">
                <i class="fas fa-slot-machine"></i>
              </div>
              <h5 class="card-title">슬롯</h5>
              <p class="card-text">다양한 테마의 슬롯 게임을 즐기세요.</p>
              <button class="btn modern-btn" @click="openGameModal('slot')">지금 시작</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-4">
          <div class="card modern-card">
            <div class="card-body">
              <div class="card-icon">
                <i class="fas fa-futbol"></i>
              </div>
              <h5 class="card-title">스포츠</h5>
              <p class="card-text">최신 스포츠 이벤트에 베팅하고 승리하세요.</p>
              <button class="btn modern-btn" @click="openGameModal('sports')">지금 시작</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 이벤트 섹션 -->
      <div class="row mt-1">
        <div class="col-6">
          <div class="events-container">
            <div class="event-card">
              <img src="./../assets/event1.jpg" alt="이벤트 1" class="event-image">
              <div class="event-content">
                <h4>신규 회원 보너스</h4>
                <p>첫 입금 시 입금액의 100% 보너스</p>
                <button class="btn event-btn">자세히 보기</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="events-container">
            <div class="event-card">
              <img src="./../assets/event2.jpg" alt="이벤트 2" class="event-image">
              <div class="event-content">
                <h4>추석 맞이 이벤트</h4>
                <p>추석연휴 72시간 입금액의 20% 보너스</p>
                <button class="btn event-btn">자세히 보기</button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <div class="promo-banner">
            <div class="promo-content">
              <h2 class="promo-title">최대 100% 보너스를 받으세요!</h2>
              <p class="promo-text">지인추천시 최대 100% 보너스를 드립니다. 지금 등록하고 혜택을 누리세요!</p>
              <button class="btn promo-btn">지인 등록</button>
            </div>
          </div>
        </div>
      </div>

      <!-- 최근 당첨자 목록 섹션 -->
      <div class="row mt-5">
        <div class="col-6">
          <h3 class="mb-3">최근 당첨자</h3>
          <div class="winners-list">
            <transition-group name="fade">
              <div v-for="winner in displayedWinners" :key="winner.id" class="winner-item">
                <span class="winner-name">{{ winner.name }}</span>
                <span class="winner-game">{{ winner.game }}</span>
                <span class="winner-amount">{{ winner.amount }}</span>
              </div>
            </transition-group>
          </div>
        </div>
        <div class="col-6">
          <h3 class="mb-3">실시간 게임 통계</h3>
          <div class="game-stats">
            <div class="stat-item">
              <span class="stat-label">활성 플레이어</span>
              <span class="stat-value">{{ activePlayers }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-label">총 베팅 금액</span>
              <span class="stat-value">{{ totalBets }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-label">오늘의 대박</span>
              <span class="stat-value">{{ biggestWin }}</span>
            </div>
            <div class="stat-item">
              <span class="stat-label">금일 승률</span>
              <span class="stat-value">{{ todayWinRate }}</span>
            </div>
            <!-- <div class="stat-item">
              <span class="stat-label">최다 베팅 게임</span>
              <span class="stat-value">{{ mostBettedGame }}</span>
            </div> -->
          </div>
        </div>
      </div>

      <!-- 고객 지원 섹션 -->
      <div class="row mt-5">
        <div class="col-12">
          <h3 class="mb-3">고객 지원</h3>
          <div class="support-options">
            <button class="btn support-btn mx-2" @click="openLiveChat">
              <i class="fas fa-comments"></i> 실시간 채팅
            </button>
            <button class="btn support-btn mx-2" @click="showContactForm">
              <i class="fas fa-envelope"></i> 문의하기
            </button>
            <button class="btn support-btn mx-2" @click="showFAQ">
              <i class="fas fa-question-circle"></i> 자주 묻는 질문
            </button>
            <button class="btn support-btn mx-2" @click="openTelegram">
              <i class="fab fa-telegram"></i> 텔레그램
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'MainContent',
  setup() {
    const store = useStore();

    const generateRandomWinners = (count) => {
      const names = ['김','김','김','김','김','김','김', '이', '박', '최', '정', '강', '조', '윤', '장', '임', '한', '오', '서', '신', '권', '황', '안', '송', '전', '홍'];
      const games = ['바카라', '슬롯', '미니게임', '스포츠', '룰렛'];
      
      const generateAmount = (game) => {
        let amount;
        switch (game) {
          case '바카라':
            amount = Math.floor(Math.random() * 8001) + 2000; // 2,000,000원 이상
            break;
          case '슬롯':
            amount = Math.floor(Math.random() * 1001); // 1,000,000원 이하
            break;
          case '미니게임':
            amount = Math.floor(Math.random() * 1001); // 1,000,000원 이하
            break;
          case '스포츠':
            amount = Math.floor(Math.random() * 2001) + 1000; // 1,000,000원 이상 3,000,000원 이하
            break;
          case '룰렛':
            amount = Math.floor(Math.random() * 301); // 300,000원 이하
            break;
          default:
            amount = 0;
        }
        return amount * 1000; // 1000단위로 변환
      };

      return Array.from({ length: count }, (_, index) => {
        const randomName = names[Math.floor(Math.random() * names.length)] + '**';
        const randomGame = games[Math.floor(Math.random() * games.length)];
        const randomAmount = generateAmount(randomGame);

        return {
          id: index + 1,
          name: randomName,
          game: randomGame,
          amount: '₩' + randomAmount.toLocaleString()
        };
      });
    };

    const allWinners = ref(generateRandomWinners(50));

    // 나머지 코드는 그대로 유지
    const displayedWinners = ref([]);
    let currentIndex = 0;
    let timer;

    const updateDisplayedWinners = () => {
      displayedWinners.value = allWinners.value.slice(currentIndex, currentIndex + 5);
      currentIndex = (currentIndex + 1) % (allWinners.value.length - 4);
    };

    const activePlayers = ref(1234);
    const totalBets = ref('₩123,456,789');
    const biggestWin = ref('₩10,000,000');
    const todayWinRate = ref('68%');
    const mostBettedGame = ref('블랙잭');

    const openGameModal = (tab) => {
      store.commit('SET_GAME_MODAL_TAB', tab);
      store.commit('SET_GAME_MODAL_OPEN', true);
    };

    const openLiveChat = () => {
      console.log('Opening live chat');
    };

    const showContactForm = () => {
      console.log('Showing contact form');
    };

    const showFAQ = () => {
      console.log('Showing FAQ page');
    };

    const openTelegram = () => {
      console.log('Opening Telegram');
    };

    onMounted(() => {
      console.log('Component mounted');
      updateDisplayedWinners();
      timer = setInterval(updateDisplayedWinners, 2500);
    });

    onUnmounted(() => {
      clearInterval(timer);
    });

    return {
      displayedWinners,
      activePlayers,
      totalBets,
      biggestWin,
      todayWinRate,
      mostBettedGame,
      openGameModal,
      openLiveChat,
      showContactForm,
      showFAQ,
      openTelegram,store
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;600;700&display=swap');
.stats-title {
  color: #ffd700;
  font-size: 1.5rem;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.game-stats {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
}

.stat-icon {
  font-size: 1.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 215, 0, 0.2);
  border-radius: 50%;
  margin-right: 15px;
  color: #ffd700;
}

.stat-info {
  flex-grow: 1;
}

.stat-label {
  display: block;
  font-size: 0.9rem;
  color: #a0a0a0;
  margin-bottom: 5px;
}

.stat-value {
  display: block;
  font-size: 1.3rem;
  font-weight: 700;
  color: #ffffff;
  margin-left: 8px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 215, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 215, 0, 0);
  }
}

.stat-item:nth-child(1) .stat-icon {
  animation: pulse 2s infinite;
}
.main-content {
  position: relative;
  background: linear-gradient(to right, #2e272a, #000000);
  font-family: 'Roboto', sans-serif;
  padding: 20px 0;
  color: #ffffff;
  overflow: hidden; /* Ensures any overflow from the animation is hidden */
}

.dreamy-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(46, 39, 42, 0.8), rgba(0, 0, 0, 0.8));
  z-index: -1;
  overflow: hidden;
}

@keyframes floatingParticles {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-30px) translateX(20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0) translateX(0);
    opacity: 0.8;
  }
}

.particle {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  animation: floatingParticles 10s infinite ease-in-out;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

/* Create multiple particles with varying sizes and positions */
.particle:nth-child(1) {
  width: 10px;
  height: 10px;
  top: 20%;
  left: 30%;
  animation-duration: 8s;
}

.particle:nth-child(2) {
  width: 15px;
  height: 15px;
  top: 40%;
  left: 70%;
  animation-duration: 12s;
}

.particle:nth-child(3) {
  width: 8px;
  height: 8px;
  top: 60%;
  left: 50%;
  animation-duration: 9s;
}

.particle:nth-child(4) {
  width: 12px;
  height: 12px;
  top: 80%;
  left: 20%;
  animation-duration: 11s;
}

/* Additional styles from your original code */
.stats-title {
  color: #ffd700;
  font-size: 1.5rem;
  font-weight: 700;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.modern-card {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 15px;
  height: 250px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  backdrop-filter: blur(5px);
}

.modern-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.card-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #ffd700;
}

.card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color:#ffeeff;
}

.card-text {
  font-size: 1rem;
  color: #e0e0e0;
  margin-bottom: 1.5rem;
}

.modern-btn, .promo-btn, .vip-btn, .support-btn, .app-btn, .guide-btn {
  background: linear-gradient(45deg, #672879, #feca57);
  border: none;
  border-radius: 25px;
  padding: 10px 25px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.modern-btn:hover, .promo-btn:hover, .vip-btn:hover, .support-btn:hover, .app-btn:hover, .guide-btn:hover {
  background: linear-gradient(45deg, #feca57, #ff6b6b);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.winners-list {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  height: 300px; /* Adjust as needed */
  overflow: hidden;
}

.winner-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  transition: all 0.3s ease;
}

.winner-item:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateX(5px);
}

.winner-name, .winner-game, .winner-amount {
  flex: 1;
}

.winner-amount {
  text-align: right;
  color: #ffd700;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.promo-banner {
  background: url('./../assets/casino_main.jpg') no-repeat center center;
  background-size: cover;
  padding: 40px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.promo-content {
  background-color: rgb(0 0 0 / 40%);
  border-radius: 10px;
  padding: 30px;
  display: inline-block;
}

.promo-title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}

.promo-text {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #ffffff;
  font-weight: 600;
}
.events-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.event-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease;
}

.event-card:hover .event-image {
  transform: scale(1.05);
}

.event-content {
  padding: 20px;
}

.event-content h4 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #ffd700;
}

.event-content p {
  font-size: 0.9rem;
  color: #e0e0e0;
  margin-bottom: 15px;
}

.event-btn {
  background: linear-gradient(45deg, #ff6b6b, #a55aa2);
  border: none;
  border-radius: 25px;
  padding: 8px 20px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.event-btn:hover {
  background: linear-gradient(45deg, #feca57, #ff6b6b);
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.support-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.support-btn i {
  margin-right: 8px;
}

/* 반응형 디자인을 위한 미디어 쿼리 수정 */
@media (max-width: 768px) {
  .support-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .support-btn {
    width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .support-btn {
    width: 100%;
  }
}


h1.display-4 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p.lead {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 0;
}
</style>

