<template>
  <div class="password-change">
    <h2 class="text-center mb-4">비밀번호 변경</h2>
    <form @submit.prevent="changePassword" class="password-form">
      <div class="form-group">
        <label for="newPassword">새 비밀번호</label>
        <input 
          type="password" 
          id="newPassword" 
          v-model="newPassword" 
          required 
          class="form-control"
          @input="checkPasswordStrength"
        >
        <div v-if="passwordValidation.message" class="error-message">
          {{ passwordValidation.message }}
        </div>
        <div class="password-strength" :class="passwordStrengthClass">
          비밀번호 강도: {{ passwordStrength }}
        </div>
      </div>
      <div class="form-group">
        <label for="confirmPassword">새 비밀번호 확인</label>
        <input 
          type="password" 
          id="confirmPassword" 
          v-model="confirmPassword" 
          required 
          class="form-control"
        >
        <div v-if="confirmPasswordValidation.message" class="error-message">
          {{ confirmPasswordValidation.message }}
        </div>
      </div>
      <button type="submit" class="btn-submit" :disabled="!isFormValid">
        비밀번호 변경
      </button>
    </form>
    <div v-if="changePasswordError" class="error-message">
      {{ changePasswordError }}
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

export default {
  name: 'PasswordChange',
  setup() {
    const store = useStore();
    const newPassword = ref('');
    const confirmPassword = ref('');
    const passwordStrength = ref('');
    const changePasswordError = ref('');

    const passwordValidation = computed(() => {
      if (!newPassword.value) {
        return { isValid: false, message: "비밀번호를 입력하세요" };
      }
      if (newPassword.value.match(/\s/gi)) {
        return { isValid: false, message: "공백은 사용할 수 없습니다" };
      }
      if (newPassword.value.length < 6 || newPassword.value.length > 18) {
        return { isValid: false, message: "비밀번호는 6~18자입니다" };
      }
      if (!newPassword.value.match(/[a-z]/gi)) {
        return { isValid: false, message: "비밀번호는 영문을 포함합니다" };
      }
      if (!newPassword.value.match(/[0-9]/gi)) {
        return { isValid: false, message: "비밀번호는 숫자를 포함합니다" };
      }
      return { isValid: true, message: "" };
    });

    const confirmPasswordValidation = computed(() => {
      if (!confirmPassword.value) {
        return { isValid: false, message: "비밀번호를 확인하세요" };
      }
      if (newPassword.value !== confirmPassword.value) {
        return { isValid: false, message: "비밀번호가 일치하지 않습니다" };
      }
      return { isValid: true, message: "" };
    });

    const checkPasswordStrength = () => {
      const password = newPassword.value;
      let strength = 0;
      if (password.length >= 8) strength++;
      if (password.match(/[a-z]+/)) strength++;
      if (password.match(/[A-Z]+/)) strength++;
      if (password.match(/[0-9]+/)) strength++;
      if (password.match(/[$@#&!]+/)) strength++;

      switch (strength) {
        case 0:
        case 1:
          passwordStrength.value = '매우 약함';
          break;
        case 2:
          passwordStrength.value = '약함';
          break;
        case 3:
          passwordStrength.value = '중간';
          break;
        case 4:
          passwordStrength.value = '강함';
          break;
        case 5:
          passwordStrength.value = '매우 강함';
          break;
      }
    };

    const passwordStrengthClass = computed(() => {
      switch (passwordStrength.value) {
        case '매우 약함':
        case '약함':
          return 'weak';
        case '중간':
          return 'medium';
        case '강함':
        case '매우 강함':
          return 'strong';
        default:
          return '';
      }
    });

    const isFormValid = computed(() => {
      return passwordValidation.value.isValid &&
             confirmPasswordValidation.value.isValid &&
             passwordStrength.value !== '매우 약함' &&
             passwordStrength.value !== '약함';
    });

    const changePassword = async () => {
      if (!isFormValid.value) return;
      
      try {
        await axios.post(
          'https://goldfish-app-cklru.ondigitalocean.app/api/auth/change-password',
          {
            password: newPassword.value,
            password_confirm: confirmPassword.value
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `bearer ${store.state.jwt}`
            }
          }
        ).then((response)=>{
          if(response.status==200){
            console.log(response)
            alert('비밀번호가 성공적으로 변경되었습니다.');
  
            store.commit('setJwt',response.data.jwt)
            // store.commit('SET_INFO_MODAL_OPEN',false)
            store.dispatch('closeInfoModal')
          }
        }).catch((error)=>{
          if(error.response.data.error.status==400){
            alert(error.response.data.error.message)
          }
          // if(error.error.status==400){
          //   alert(error.error.message)
          // }
        })
        
        

        newPassword.value = '';
        confirmPassword.value = '';
        passwordStrength.value = '';
        changePasswordError.value = '';
      } catch (error) {
        changePasswordError.value = error.response?.data?.message || '비밀번호 변경 중 오류가 발생했습니다.';
      }
    };

    return {
      newPassword,
      confirmPassword,
      passwordStrength,
      passwordStrengthClass,
      passwordValidation,
      confirmPasswordValidation,
      isFormValid,
      changePassword,
      checkPasswordStrength,
      changePasswordError
    };
  }
};
</script>

<style scoped>
.password-change {
  max-width: 100vw;  /* Default for mobile */
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
}

@media (min-width: 769px) {
  .password-change {
    max-width: 500px;  /* For PC */
  }
}

.password-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

label {
  font-size: 0.9em;
  color: #b0b0b0;
}

.form-control {
  padding: 10px;
  background-color: #1c2833;
  border: 1px solid #34495e;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1em;
}

.form-control:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
}

.password-strength {
  font-size: 0.8em;
  padding: 5px;
  border-radius: 3px;
}

.password-strength.weak {
  color: #e74c3c;
}

.password-strength.medium {
  color: #f39c12;
}

.password-strength.strong {
  color: #2ecc71;
}

.btn-submit {
  padding: 10px;
  background: linear-gradient(145deg, #3498db, #2980b9);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-submit:hover:not(:disabled) {
  background: linear-gradient(145deg, #2980b9, #3498db);
  transform: translateY(-2px);
}

.btn-submit:disabled {
  background: linear-gradient(145deg, #34495e, #2c3e50);
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .password-change {
    padding: 15px;
  }
}
</style>