<template>
  <div class="register-form">
    <form @submit.prevent="getVerifyCode" class="form-content">
      <div class="form-group">
        <label for="username">아이디</label>
        <input
          id="username"
          v-model="inputNow.username"
          @input="validate('USERNAME')"
          type="text"
          :class="{ 'form-control': true, 'is-invalid': !isValid.username }"
          placeholder="아이디를 입력하세요"
          required
        >
        <div class="error-message">{{ message.username }}</div>
      </div>

      <div class="form-group">
        <label for="nickname">닉네임</label>
        <input
          id="nickname"
          v-model="inputNow.nickname"
          @input="validate('NICKNAME')"
          type="text"
          :class="{ 'form-control': true, 'is-invalid': !isValid.nickname }"
          placeholder="닉네임을 입력하세요"
          required
        >
        <div class="error-message">{{ message.nickname }}</div>
      </div>

      <div class="form-group">
        <label for="password">비밀번호</label>
        <input
          id="password"
          v-model="inputNow.password"
          @input="validate('PASSWORD')"
          type="password"
          :class="{ 'form-control': true, 'is-invalid': !isValid.password }"
          placeholder="비밀번호를 입력하세요"
          required
        >
        <div class="error-message">{{ message.password }}</div>
      </div>

      <div class="form-group">
        <label for="password_confirm">비밀번호 확인</label>
        <input
          id="password_confirm"
          v-model="inputNow.password_confirm"
          @input="validate('PASSWORD_CONFIRM')"
          type="password"
          :class="{ 'form-control': true, 'is-invalid': !isValid.password_confirm }"
          placeholder="비밀번호를 다시 입력하세요"
          required
        >
        <div class="error-message">{{ message.password_confirm }}</div>
      </div>

      <div class="form-group">
        <label for="bank">은행명</label>
        <select
          id="bank"
          v-model="inputNow.userinfo.bank"
          @change="validate('BANK')"
          :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.bank }"
          required
        >
          <option value="" disabled selected>은행을 선택하세요</option>
          <option v-for="(bank, key) in BANK" :key="key" :value="key">
            {{ bank }}
          </option>
        </select>
        <div class="error-message">{{ message.userinfo.bank }}</div>
      </div>

      <div class="form-group">
        <label for="depositor">예금주</label>
        <input
          id="depositor"
          v-model="inputNow.userinfo.depositor"
          @input="validate('DEPOSITOR')"
          type="text"
          :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.depositor }"
          placeholder="예금주명을 입력하세요"
          required
        >
        <div class="error-message">{{ message.userinfo.depositor }}</div>
      </div>

      <div class="form-group">
        <label for="account">계좌번호</label>
        <input
          id="account"
          v-model="inputNow.userinfo.account"
          @input="validate('ACCOUNT')"
          type="text"
          :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.account }"
          placeholder="계좌번호를 입력하세요"
          required
        >
        <div class="error-message">{{ message.userinfo.account }}</div>
      </div>

      <div class="form-group">
        <label for="password_withdraw">출금비밀번호</label>
        <input
          id="password_withdraw"
          v-model="inputNow.userinfo.password_withdraw"
          @input="validate('PASSWORD_WITHDRAW')"
          type="password"
          :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.password_withdraw }"
          placeholder="출금비밀번호를 입력하세요"
          required
        >
        <div class="error-message">{{ message.userinfo.password_withdraw }}</div>
      </div>

      <div class="form-group">
        <label for="mobile">전화번호</label>
        <div class="input-group">
          <select
            v-if="REGISTER.IS_ENABLE_CARRIER"
            v-model="inputNow.userinfo.carrier"
            @change="validate('CARRIER')"
            :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.carrier }"
            required
          >
            <option value="" disabled selected>통신사</option>
            <option v-for="(carrier, key) in CARRIER" :key="key" :value="key">
              {{ carrier }}
            </option>
          </select>
          <input
            id="mobile"
            v-model="inputNow.userinfo.mobile"
            @input="validate('MOBILE')"
            type="text"
            :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.mobile }"
            placeholder="전화번호를 입력하세요"
            required
          >
        </div>
        <div class="error-message">{{ message.userinfo.mobile }}</div>
      </div>

      <div v-if="REGISTER.IS_ENABLE_GENDER || REGISTER.IS_ENABLE_BIRTHDATE" class="form-group">
        <div class="input-group">
          <select
            v-if="REGISTER.IS_ENABLE_GENDER"
            v-model="inputNow.userinfo.gender"
            @change="validate('GENDER')"
            :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.gender }"
            required
          >
            <option value="" disabled selected>성별</option>
            <option v-for="(gender, key) in GENDER" :key="key" :value="key">
              {{ gender }}
            </option>
          </select>
          <input
            v-if="REGISTER.IS_ENABLE_BIRTHDATE"
            v-model="inputNow.userinfo.birthdate"
            @input="validate('BIRTHDATE')"
            type="text"
            :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.birthdate }"
            placeholder="생년월일을 입력하세요"
            required
          >
        </div>
        <div class="error-message">
          {{ message.userinfo.gender || message.userinfo.birthdate }}
        </div>
      </div>

      <div class="form-group">
        <label for="recommender">추천인코드</label>
        <input
          id="recommender"
          v-model="inputNow.userinfo.recommender"
          @input="validate('RECOMMENDER')"
          type="text"
          :class="{ 'form-control': true, 'is-invalid': !isValid.userinfo.recommender }"
          placeholder="추천인코드를 입력하세요"
        >
        <div class="error-message">{{ message.userinfo.recommender }}</div>
      </div>

      <button type="submit" class="btn-submit" :disabled="isError">
        회원가입
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import {
  getHeaders,
  fakeEncrypt,
  fakeDecrypt,
  successSwal,
  errorSwal,
} from "@/composable/utils.js";
import { BANK, CARRIER, GENDER } from "@/composable/vars.js";

import axios from "axios";
import Swal from "sweetalert2";

// 라우터
const router = useRouter();

// 인풋 변수
const inputNow = ref({
  username: null,
  nickname: null,
  password: null,
  password_confirm: null,
  userinfo: {
    bank: "은행명",
    depositor: null,
    account: null,
    password_withdraw: null,
    carrier: "통신사",
    mobile: null,
    gender: "성별",
    birthdate: null,
    recommender: null,
  },
});
const inputNew = ref({ userinfo: {} });

// 벨리데이트 변수
const isValid = ref({
  username: true,
  nickname: true,
  password: true,
  password_confirm: true,
  userinfo: {
    bank: true,
    depositor: true,
    account: true,
    password_withdraw: true,
    carrier: true,
    mobile: true,
    gender: true,
    birthdate: true,
    recommender: true,
  },
});

// 벨리데이트 에러
const message = ref({
  username: null,
  nickname: null,
  password: null,
  password_confirm: null,
  userinfo: {
    bank: null,
    depositor: null,
    account: null,
    password_withdraw: null,
    carrier: null,
    mobile: null,
    gender: null,
    birthdate: null,
    recommender: null,
  },
});

// 벨리데이트 디바운스
const debounce = ref({
  username: null,
  nickname: null,
  recommender: null,
  delay: 1000,
});

// 벨리데이트 완료
const isValidate = ref(false);

// 문자인증 변수
const verifyCode = ref(null);
const verifyInput = ref(null);

// 설정
const REGISTER = ref({});

// 에러
const isError = ref(false);

// 바디데이터 생성
const getInputNew = () => {
  inputNow.value.username &&
    (inputNew.value.username = inputNow.value.username);
  inputNow.value.nickname &&
    (inputNew.value.nickname = inputNow.value.nickname);
  inputNow.value.password &&
    (inputNew.value.password = inputNow.value.password);
  inputNow.value.password_confirm &&
    (inputNew.value.password_confirm = inputNow.value.password_confirm);

  inputNow.value.userinfo.bank &&
    (inputNew.value.userinfo.bank = Number(inputNow.value.userinfo.bank));
  inputNow.value.userinfo.depositor &&
    (inputNew.value.userinfo.depositor = inputNow.value.userinfo.depositor);
  inputNow.value.userinfo.account &&
    (inputNew.value.userinfo.account = inputNow.value.userinfo.account.replace(
      /[^0-9]/gi,
      ""
    ));
  inputNow.value.userinfo.password_withdraw &&
    (inputNew.value.userinfo.password_withdraw =
      inputNow.value.userinfo.password_withdraw);
  inputNow.value.userinfo.carrier &&
    (inputNew.value.userinfo.carrier = Number(inputNow.value.userinfo.carrier));
  inputNow.value.userinfo.mobile &&
    (inputNew.value.userinfo.mobile = inputNow.value.userinfo.mobile.replace(
      /[^0-9]/gi,
      ""
    ));
  inputNow.value.userinfo.gender &&
    (inputNew.value.userinfo.gender = Number(inputNow.value.userinfo.gender));
  inputNow.value.userinfo.birthdate &&
    REGISTER.value.IS_ENABLE_BIRTHDATE &&
    (inputNew.value.userinfo.birthdate =
      inputNow.value.userinfo.birthdate.replace(/[^0-9]/gi, ""));
  inputNow.value.userinfo.recommender &&
    (inputNew.value.userinfo.recommender = inputNow.value.userinfo.recommender);

  return inputNew.value;
};

// 벨리데이트(비동기)
const asyncValidate = (params) => {
  clearTimeout(debounce.value.username);
  return new Promise((resolve) => {
    debounce.value.username = setTimeout(
      async () => {
        if (params === "USERNAME" || !params) {
          if (
            (await requestValidateGet(`username=${inputNow.value.username}`)) ||
            (await requestValidateGet(`recommender=${inputNow.value.username}`))
          ) {
            isValid.value.username = false;
            message.value.username = "중복된 아이디입니다";
          } else {
            isValid.value.username = true;
            message.value.username = "";
          }
        }

        if (params === "NICKNAME" || !params) {
          if (await requestValidateGet(`nickname=${inputNow.value.nickname}`)) {
            isValid.value.nickname = false;
            message.value.nickname = "중복된 닉네임입니다";
          } else {
            isValid.value.nickname = true;
            message.value.nickname = "";
          }
        }

        if (params === "RECOMMENDER" || !params) {
          if (
            inputNow.value.userinfo.recommender &&
            !(await requestValidateGet(
              `recommender=${inputNow.value.userinfo.recommender}`
            ))
          ) {
            isValid.value.userinfo.recommender = false;
            message.value.userinfo.recommender = "추천인코드를 확인하세요";
          } else {
            isValid.value.userinfo.recommender = true;
            message.value.userinfo.recommender = "";
          }
        }

        resolve();
      },
      params ? debounce.value.delay : 0
    );
  });
};

// 벨리데이트(동기)
const validate = async (params) => {
  isValidate.value = false;

  if (!params) {
    getInputNew();
  }

  if (params === "USERNAME" || !params) {
    if (!inputNow.value.username) {
      isValid.value.username = false;
      message.value.username = "아이디를 입력하세요";
    } else if (inputNow.value.username.match(/\s/gi)) {
      isValid.value.username = false;
      message.value.username = "공백은 사용할 수 없습니다";
    } else if (inputNow.value.username.match(/[^a-z0-9]/g)) {
      isValid.value.username = false;
      message.value.username = "아이디는 소문자 또는 숫자입니다";
    } else if (
      inputNow.value.username.length < 6 ||
      inputNow.value.username.length > 18
    ) {
      isValid.value.username = false;
      message.value.username = "아이디는 6~18자입니다";
    } else if (!inputNow.value.username.match(/[a-z]/gi)) {
      isValid.value.username = false;
      message.value.username = "아이디는 영문을 포함합니다";
    } else {
      if (params === "USERNAME") {
        await asyncValidate("USERNAME");
      }
    }
  }

  if (params === "NICKNAME" || !params) {
    if (!inputNow.value.nickname) {
      isValid.value.nickname = false;
      message.value.nickname = "닉네임을 입력하세요";
    } else if (inputNow.value.nickname.match(/\s/gi)) {
      isValid.value.nickname = false;
      message.value.nickname = "공백은 사용할 수 없습니다";
    } else if (inputNow.value.nickname.match(/[ㄱ-ㅣ]/gi)) {
      isValid.value.nickname = false;
      message.value.nickname = "초성은 사용할 수 없습니다";
    } else if (inputNow.value.nickname.match(/[^a-z0-9가-힣]/gi)) {
      isValid.value.nickname = false;
      message.value.nickname = "특수문자는 사용할 수 없습니다";
    } else if (
      inputNow.value.nickname.length < 3 ||
      inputNow.value.nickname.length > 9
    ) {
      isValid.value.nickname = false;
      message.value.nickname = "닉네임은 3~9자입니다";
    } else {
      if (params === "NICKNAME") {
        await asyncValidate("NICKNAME");
      }
    }
  }

  if (params === "PASSWORD" || !params) {
    if (!inputNow.value.password) {
      isValid.value.password = false;
      message.value.password = "비밀번호를 입력하세요";
    } else if (inputNow.value.password.match(/\s/gi)) {
      isValid.value.password = false;
      message.value.password = "공백은 사용할 수 없습니다";
    } else if (
      inputNow.value.password.length < 6 ||
      inputNow.value.password.length > 18
    ) {
      isValid.value.password = false;
      message.value.password = "비밀번호는 6~18자입니다";
    } else if (!inputNow.value.password.match(/[a-z]/gi)) {
      isValid.value.password = false;
      message.value.password = "비밀번호는 영문을 포함합니다";
    } else if (!inputNow.value.password.match(/[0-9]/gi)) {
      isValid.value.password = false;
      message.value.password = "비밀번호는 숫자를 포함합니다";
    } else {
      isValid.value.password = true;
      message.value.password = "";
    }
  }

  if (params === "PASSWORD_CONFIRM" || !params) {
    if (!inputNow.value.password_confirm) {
      isValid.value.password_confirm = false;
      message.value.password_confirm = "비밀번호를 확인하세요";
    } else if (inputNow.value.password !== inputNow.value.password_confirm) {
      isValid.value.password_confirm = false;
      message.value.password_confirm = "비밀번호가 일치하지 않습니다";
    } else {
      isValid.value.password_confirm = true;
      message.value.password_confirm = "";
    }
  }

  if (params === "BANK" || !params) {
    if (inputNow.value.userinfo.bank === "0") {
      isValid.value.userinfo.bank = false;
      message.value.userinfo.bank = "은행명을 입력하세요";
    } else {
      isValid.value.userinfo.bank = true;
      message.value.userinfo.bank = "";
    }
  }

  if (params === "DEPOSITOR" || !params) {
    if (!inputNow.value.userinfo.depositor) {
      isValid.value.userinfo.depositor = false;
      message.value.userinfo.depositor = "예금주명을 입력하세요";
    } else if (inputNow.value.userinfo.depositor.match(/\s/gi)) {
      isValid.value.userinfo.depositor = false;
      message.value.userinfo.depositor = "공백은 사용할 수 없습니다";
    } else if (inputNow.value.userinfo.depositor.match(/[ㄱ-ㅣ]/gi)) {
      isValid.value.userinfo.depositor = false;
      message.value.userinfo.depositor = "초성은 사용할 수 없습니다";
    } else if (inputNow.value.userinfo.depositor.match(/[^가-힣]/g)) {
      isValid.value.userinfo.depositor = false;
      message.value.userinfo.depositor = "예금주명은 한글입니다";
    } else if (
      inputNow.value.userinfo.depositor.length < 2 ||
      inputNow.value.userinfo.depositor.length > 9
    ) {
      isValid.value.userinfo.depositor = false;
      message.value.userinfo.depositor = "예금주명은 2~9자입니다";
    } else {
      isValid.value.userinfo.depositor = true;
      message.value.userinfo.depositor = "";
    }
  }

  if (params === "ACCOUNT" || !params) {
    if (!inputNow.value.userinfo.account) {
      isValid.value.userinfo.account = false;
      message.value.userinfo.account = "계좌번호를 입력하세요";
    } else if (inputNow.value.userinfo.account.match(/[^0-9-/\s]/gi)) {
      isValid.value.userinfo.account = false;
      message.value.userinfo.account = "계좌번호는 숫자입니다";
    } else if (
      inputNow.value.userinfo.account.replace(/[^0-9]/gi, "").length < 10 ||
      inputNow.value.userinfo.account.replace(/[^0-9]/gi, "").length > 15
    ) {
      isValid.value.userinfo.account = false;
      message.value.userinfo.account = "계좌번호는 10~15자입니다";
    } else {
      isValid.value.userinfo.account = true;
      message.value.userinfo.account = "";
    }
  }

  if (params === "PASSWORD_WITHDRAW" || !params) {
    if (!inputNow.value.userinfo.password_withdraw) {
      isValid.value.userinfo.password_withdraw = false;
      message.value.userinfo.password_withdraw = "출금비밀번호를 입력하세요";
    } else if (inputNow.value.userinfo.password_withdraw.match(/\s/gi)) {
      isValid.value.userinfo.password_withdraw = false;
      message.value.userinfo.password_withdraw = "공백은 사용할 수 없습니다";
    } else if (
      inputNow.value.userinfo.password_withdraw.length < 6 ||
      inputNow.value.userinfo.password_withdraw.length > 18
    ) {
      isValid.value.userinfo.password_withdraw = false;
      message.value.userinfo.password_withdraw = "출금비밀번호는 6~18자입니다";
    } else if (!inputNow.value.userinfo.password_withdraw.match(/[a-z]/gi)) {
      isValid.value.userinfo.password_withdraw = false;
      message.value.userinfo.password_withdraw =
        "출금비밀번호는 영문을 포함합니다";
    } else if (!inputNow.value.userinfo.password_withdraw.match(/[0-9]/gi)) {
      isValid.value.userinfo.password_withdraw = false;
      message.value.userinfo.password_withdraw =
        "출금비밀번호는 숫자를 포함합니다";
    } else {
      isValid.value.userinfo.password_withdraw = true;
      message.value.userinfo.password_withdraw = "";
    }
  }

  if (params === "CARRIER" || !params) {
    if (
      REGISTER.value.IS_ENABLE_CARRIER &&
      inputNow.value.userinfo.carrier === "0"
    ) {
      isValid.value.userinfo.carrier = false;
      message.value.userinfo.carrier = "통신사를 입력하세요";
    } else {
      isValid.value.userinfo.carrier = true;
      message.value.userinfo.carrier = "";
    }
  }

  if (params === "MOBILE" || !params) {
    if (!inputNow.value.userinfo.mobile) {
      isValid.value.userinfo.mobile = false;
      message.value.userinfo.mobile = "전화번호를 입력하세요";
    } else if (inputNow.value.userinfo.mobile[0] !== "0") {
      isValid.value.userinfo.mobile = false;
      message.value.userinfo.mobile = "전화번호를 확인하세요";
    } else if (inputNow.value.userinfo.mobile.match(/[^0-9-/\s]/gi)) {
      isValid.value.userinfo.mobile = false;
      message.value.userinfo.mobile = "전화번호는 숫자입니다";
    } else if (
      inputNow.value.userinfo.mobile.replace(/[^0-9]/gi, "").length < 9 ||
      inputNow.value.userinfo.mobile.replace(/[^0-9]/gi, "").length > 12
    ) {
      isValid.value.userinfo.mobile = false;
      message.value.userinfo.mobile = "전화번호는 9~12자입니다";
    } else {
      isValid.value.userinfo.mobile = true;
      message.value.userinfo.mobile = "";
    }
  }

  if (params === "GENDER" || !params) {
    if (
      REGISTER.value.IS_ENABLE_GENDER &&
      inputNow.value.userinfo.gender === "0"
    ) {
      isValid.value.userinfo.gender = false;
      message.value.userinfo.gender = "성별을 입력하세요";
    } else {
      isValid.value.userinfo.gender = true;
      message.value.userinfo.gender = "";
    }
  }

  if (params === "BIRTHDATE" || !params) {
    if (REGISTER.value.IS_ENABLE_BIRTHDATE) {
      if (!inputNow.value.userinfo.birthdate) {
        isValid.value.userinfo.birthdate = false;
        message.value.userinfo.birthdate = "생년월일을 입력하세요";
      } else if (inputNow.value.userinfo.birthdate.match(/[^0-9-/\s]/gi)) {
        isValid.value.userinfo.birthdate = false;
        message.value.userinfo.birthdate = "생년월일은 숫자입니다";
      } else if (
        inputNow.value.userinfo.birthdate.replace(/[^0-9]/gi, "").length !== 8
      ) {
        isValid.value.userinfo.birthdate = false;
        message.value.userinfo.birthdate = "생년월일은 8자입니다";
      } else {
        isValid.value.userinfo.birthdate = true;
        message.value.userinfo.birthdate = "";
      }
    }
  }

  if (params === "RECOMMENDER" || !params) {
    if (
      REGISTER.value.IS_REQUIRE_RECOMMENDER &&
      !inputNow.value.userinfo.recommender
    ) {
      isValid.value.userinfo.recommender = false;
      message.value.userinfo.recommender = "추천인코드를 입력하세요";
    } else {
      if (params === "RECOMMENDER") {
        await asyncValidate("RECOMMENDER");
      }
    }
  }

  if (
    !params &&
    isValid.value.username &&
    isValid.value.nickname &&
    isValid.value.password &&
    isValid.value.password_confirm &&
    isValid.value.userinfo.bank &&
    isValid.value.userinfo.depositor &&
    isValid.value.userinfo.account &&
    isValid.value.userinfo.password_withdraw &&
    isValid.value.userinfo.carrier &&
    isValid.value.userinfo.mobile &&
    isValid.value.userinfo.gender &&
    isValid.value.userinfo.birthdate &&
    isValid.value.userinfo.recommender
  ) {
    await asyncValidate();

    if (
      isValid.value.username &&
      isValid.value.nickname &&
      isValid.value.userinfo.recommender
    ) {
      isValidate.value = true;
    }
  }
};

// 문자인증 요청
const getVerifyCode = async () => {
  await validate();

  if (isValidate.value) {
    // 인증 필수
    if (REGISTER.value.IS_ENABLE_VERIFY) {
      axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_API_URL
        }/api/auth/verify?mobile=${inputNow.value.userinfo.mobile.replace(
          /[^0-9]/gi,
          ""
        )}`,
        headers: getHeaders(),
      })
        .then((response) => {
          // 외부 API 성공
          if (response.data === 0) {
            verifyCode.value = fakeDecrypt(
              response.headers.get("X-Do-App-With")
            );
            getVerifyInput();
          }

          // 외부 API 실패
          else {
            errorSwal("잠시 후 다시 시도하세요");
          }
        })
        .catch(() => {
          errorSwal("잠시 후 다시 시도하세요");
        })
        .finally(() => {});
    }

    // 인증 선택
    else {
      requestRegisterPost();
    }
  }
};

// 문자인증 입력
const getVerifyInput = () => {
  Swal.fire({
    icon: "info",
    title: "번호인증",
    text: "인증번호가 발송되었습니다",
    confirmButtonText: "확인",
    showCancelButton: true,
    cancelButtonText: "취소",
    showDenyButton: true,
    denyButtonText: "재발송",
    denyButtonColor: "#17a2b8",

    backdrop: false,
    input: "text",
    inputPlaceholder: "인증번호를 입력하세요",
    customClass: {
      input: "text-center",
      confirmButton: "order-1",
      cancelButton: "order-2",
      denyButton: "order-3",
    },
    // TODO: 프로덕션
    inputValue: verifyCode.value,
    preConfirm: (input) => {
      // 인증 성공
      if (verifyCode.value === input) {
        verifyInput.value = input;

        return true;
      }

      // 인증 실패
      else {
        Swal.getHtmlContainer().innerHTML = "인증번호를 확인하세요";
        Swal.getValidationMessage()?.remove();
        Swal.showValidationMessage();

        return false;
      }
    },
    preDeny: async () => {
      // 인증 요청
      try {
        Swal.getHtmlContainer().innerHTML = "인증번호가 재발송되었습니다";

        const button = Swal.getDenyButton();
        button.style.pointerEvents = "none";
        button.style.opacity = 0.65;

        setTimeout(() => {
          button.style.pointerEvents = "auto";
          button.style.opacity = 1;
        }, 60000);

        const result = await axios({
          method: "GET",
          url: `${
            process.env.VUE_APP_API_URL
          }/api/auth/verify?mobile=${inputNow.value.userinfo.mobile.replace(
            /[^0-9]/gi,
            ""
          )}`,
          headers: getHeaders(),
        });

        // 외부 API 성공
        if (result.data === 0) {
          verifyCode.value = fakeDecrypt(result.headers.get("X-Do-App-With"));

          return false;
        }

        // 외부 API 실패
        else {
          Swal.getHtmlContainer().innerHTML = "잠시 후 다시 시도하세요";
          Swal.getValidationMessage()?.remove();
          Swal.showValidationMessage();

          return false;
        }
      } catch {
        Swal.getHtmlContainer().innerHTML = "잠시 후 다시 시도하세요";
        Swal.getValidationMessage()?.remove();
        Swal.showValidationMessage();

        return false;
      }
    },
  }).then((response) => {
    if (response.isConfirmed && response.value) {
      requestRegisterPost();
    }
  });
};

// 회원가입 요청
const requestRegisterPost = () => {
  axios({
    method: "POST",
    url: `https://goldfish-app-cklru.ondigitalocean.app/api/auth/local/register-user`,
    headers: {
      ...getHeaders(),
      ...(REGISTER.value.IS_ENABLE_VERIFY && {
        "X-Do-App-With": fakeEncrypt(verifyCode.value),
      }),
    },
    data: {
      ...getInputNew(),
      ...(REGISTER.value.IS_ENABLE_VERIFY && {
        verifyInput: verifyInput.value,
      }),
    },
  })
    .then(() => {
      successSwal("회원가입이 완료되었습니다");
      
      router.push("/");
    })
    .catch(() => {
      errorSwal("잠시 후 다시 시도하세요");
    })
    .finally(() => {});
};

// 벨리데이트 요청
const requestValidateGet = async (query) => {
  try {
    const result = await axios({
      method: "GET",
      url: `https://goldfish-app-cklru.ondigitalocean.app/api/auth/validate?${query}`,
      headers: getHeaders(),
    });

    return result.data;
  } catch {
    isError.value = true;
  }
};

// 설정 요청
const requestSettingGet = () => {
  axios({
    method: "GET",
    url: `https://goldfish-app-cklru.ondigitalocean.app/api/setting`,
    headers: getHeaders(),
  })
    .then((response) => {
      REGISTER.value = response.data.data.attributes.REGISTER;
    })
    .catch(() => {
      isError.value = true;
    })
    .finally(() => {});
};

// 초기화
requestSettingGet();
</script>




<style scoped>
.register-form {
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
}

@media (min-width: 769px) {
  .register-form {
    max-width: 500px;
  }
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #d0d0d0;
}

.form-control {
  width: 100%;
  padding: 10px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1em;
}

.form-control::placeholder {
  color: #7f8c8d;
}

.input-group {
  display: flex;
  gap: 10px;
}

.input-group .form-control {
  flex: 1;
}

.btn-submit {
  width: 100%;
  padding: 12px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-submit:hover:not(:disabled) {
  background: linear-gradient(145deg, #0056b3, #007bff);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn-submit:disabled {
  background: linear-gradient(145deg, #4a4a4a, #3a3a3a);
  cursor: not-allowed;
}

.error-message {
  color: #ff4d4d;
  font-size: 0.9em;
  margin-top: 5px;
}

.is-invalid {
  border: 1px solid #ff4d4d;
}

@media (max-width: 480px) {
  .register-form {
    padding: 15px;
  }

  .btn-submit {
    font-size: 0.9em;
  }
}
</style>




