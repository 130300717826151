<template>
  <div id="app">
    <HeaderVue />
    <router-view></router-view>
    <!-- <FooterVue /> -->
  </div>
</template>

<script>
import HeaderVue from '@/views/HeaderVue.vue';
// import FooterVue from '@/views/FooterVue.vue';
import { onMounted } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'App',
  components: {
    HeaderVue,
    // FooterVue
  },
  setup(){
    const store = useStore();
    onMounted( () => {
      try {
        store.dispatch('fetchSettings');
        store.dispatch('fetchGameList');
      } catch (error) {
        console.error('Failed to fetch game list:', error);
      }
    })
    
  },
  
};
</script>

<style>
/* 웹킷 기반 브라우저를 위한 스크롤바 스타일 */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1a1a1a;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #4a4a4a;
  border-radius: 10px;
  border: 3px solid #1a1a1a;
}

::-webkit-scrollbar-thumb:hover {
  background: #141313;
}

/* Firefox를 위한 스크롤바 스타일 */
* {
  scrollbar-width: thin;
  scrollbar-color: #4a4a4a #1a1a1a;
}

/* IE와 Edge를 위한 스크롤바 스타일 */
body {
  -ms-overflow-style: none;
}

/* 전체 페이지 스크롤 동작 개선 */
html {
  scroll-behavior: smooth;
  
}
</style>
