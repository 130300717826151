<template>
  <div :class="modalClass" id="liveCasinoModal" tabindex="-1" aria-labelledby="liveCasinoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content bg-dark text-light">
        <div class="modal-header border-bottom border-secondary">
          <ul class="nav nav-tabs">
            <li class="nav-item" v-for="tab in tabs" :key="tab.value">
              <button class="nav-link" :class="{ active: localSelectedTab === tab.value }" @click="selectTab(tab.value)">
                {{ tab.name }}
              </button>
            </li>
          </ul>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div v-if="localSelectedTab === 'liveCasino'" class="game-grid">
              
              <div v-for="(casino, index) in getGames(1)" :key="index" class="game-item">
                <div class="game-thumb">
                  <!-- {{casino}} -->
                  <img :src="getImage(casino.attributes.prd_id)" :alt="casino.attributes.prd_name" class="img-fluid rounded">
                  <div class="game-overlay">
                    <button class="btn btn-primary" @click="handleGameClick(casino, 'liveCasino')">시작하기</button>
                  </div>
                </div>
                <!-- <div class="game-name">{{ casino.prd_name }}</div> -->
              </div>
            </div>
            <div v-else-if="localSelectedTab === 'slot'" class="game-grid">
              <div v-for="(slot, index) in getGames(2)" :key="index" class="game-item">
                <div class="game-thumb">
                  <img :src="getImage(slot.prd_id)" :alt="slot.prd_name" class="img-fluid rounded">
                  <div class="game-overlay">
                    <button class="btn btn-primary" @click="handleGameClick(slot, 'slot')">시작하기</button>
                  </div>
                </div>
                <div class="game-name">{{ slot.prd_name }}</div>
              </div>
            </div>
            <div v-else-if="localSelectedTab === 'sports'" class="text-center">
              <button class="btn btn-lg btn-primary me-3" @click="handleGameClick('korea','sport')">국내형</button>
              <button class="btn btn-lg btn-primary" @click="handleGameClick('game','sport')">해외형</button>
            </div>
            <div v-else-if="localSelectedTab === 'miniGame'" class="game-grid">
              <div v-for="(minigame, index) in getGames(4)" :key="index" class="game-item">
                <div class="game-thumb">
                  <img :src="getImage(minigame.prd_id)" :alt="minigame.prd_name" class="img-fluid rounded">
                  <div class="game-overlay">
                    <button class="btn btn-primary" @click="handleGameClick(minigame, 'miniGame')">시작하기</button>
                  </div>
                </div>
                <div class="game-name">{{ minigame.prd_name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
// import axios from 'axios';

const requireImage = require.context('@/assets/img', false, /\.png$/);

export default {
  name: 'GameModal',
  props: {
    selectedTab: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    let modalInstance = null;

    const isMobile = computed(() => store.state.isMobile);

    const modalClass = computed(() => {
      return isMobile.value ? 'modal fade full-screen-modal' : 'modal fade large-modal';
    });

    const tabs = ref([
      { name: '카지노', value: 'liveCasino' },
      { name: '슬롯', value: 'slot' },
      { name: '스포츠', value: 'sports' },
      { name: '미니게임', value: 'miniGame' },
    ]);

    const gameImageMap = {
      1: 'live-button-1058.png',
      2: 'live-button-1118.png',
      3: 'live-button-1112.png',
      4: 'live-button-1012.png',
      5: 'live-button-1047.png',
      5001: 'ntry.97a3b5c.png',
      5002: 'named.b83a4de.png',
      5003: 'livescore.2542621.png',
      5004: 'bet365.d35f9d8.png',
      5005: 'lotus.ff326ac.png',
      5006: 'bepick.1f3dafe.png',
      5007: 'token.e8ad9dc.png',
      5008: 'skypark.dce4daa.png',
    };

    const isLoading = ref(true);
    const localSelectedTab = computed(() => store.state.gameModalTab);

    const selectTab = (tab) => {
      store.commit('SET_GAME_MODAL_TAB', tab);
    };

    const getImage = (prd_id) => {
      const imageName = gameImageMap[prd_id] || 'default-image.png';
      try {
        return requireImage(`./${imageName}`);
      } catch (err) {
        console.error(`Image ${imageName} not found for prd_id ${prd_id}`, err);
        return requireImage('./default-image.png');
      }
    };

    const getGames = (category) => {
      const games = store.getters.getGames(category);
      console.log(`Games for category ${category}:`, games);
      return games;
    };

    watch(() => store.state.games, (newGames) => {
        if (newGames && Object.keys(newGames).length > 0) {
          isLoading.value = false;
        }
      }, { immediate: true, deep: true });

    const handleGameClick = async (game, type) => {
      if (type=='liveCasino'){
        console.log(game)
      } 
      else if ( type == 'miniGame' ){
        const agent_name = game.attributes.agent.data.attributes.name
        const { category, prd_id } = game.attributes
        const game_id = 5
        closeModal();
        store.dispatch('closeMenu')
        router.push({ name: 'minigame', params: { agent_name:agent_name, category:category, prd_id:prd_id, game_id : game_id}});

      } else if ( type == 'sport' ){
        closeModal();
        store.dispatch('closeMenu')
        router.push(`/sport/${game}`);
      }

      // 여기에 다른 게임 시작 로직 추가
    };

    onMounted(() => {
      const modalElement = document.getElementById('liveCasinoModal');
      if (modalElement) {
        modalInstance = new window.bootstrap.Modal(modalElement, {
          backdrop: 'static',
          keyboard: true  // ESC 키로 모달을 닫을 수 있게 변경
        });

        // 모달이 닫힐 때 이벤트 리스너 추가
        modalElement.addEventListener('hidden.bs.modal', () => {
          store.commit('SET_GAME_MODAL_OPEN', false);
        });
      }
      
      watch(() => store.state.gameModalOpen, (newValue) => {
        if (newValue && modalInstance) {
          modalInstance.show();
        } else if (modalInstance) {
          modalInstance.hide();
        }
      }, { immediate: true });
    });

     onUnmounted(() => {
      if (modalInstance) {
        modalInstance.dispose();
      }
      // 이벤트 리스너 제거
      const modalElement = document.getElementById('liveCasinoModal');
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', () => {
          store.commit('SET_GAME_MODAL_OPEN', false);
          document.body.style = 'hidden';
          document.body.class = 'hidden';
        });
      }
    });

    const closeModal = () => {
      if (modalInstance.value) {
        modalInstance.value.hide();
      }
      // 이벤트 리스너 제거
      const modalElement = document.getElementById('liveCasinoModal');
      if (modalElement) {
        store.commit('SET_GAME_MODAL_OPEN', false);
        document.body.style = 'hidden';
        document.body.className = 'hidden';

      }
    };

    return {
      store,
      tabs,
      localSelectedTab,
      selectTab,
      getGames,
      isLoading,
      isMobile,
      modalClass,
      getImage,
      handleGameClick,
      closeModal
    };
  },
};
</script>


<style scoped>
.modal-xl {
  max-width: 90%;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  color: #fff;
  background-color: transparent;
  border-bottom: 2px solid #007bff;
}

.game-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

.game-item {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}

.game-thumb {
  position: relative;
}

.game-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.game-item:hover .game-overlay {
  opacity: 1;
}

.game-name {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}

@media (max-width: 768px) {
  .game-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
</style>