<template>
  <div class="money-exchange">
    <!-- <h2 class="text-center mb-4">머니 환전</h2> -->
    <div class="balance-summary">
      <div class="balance-item">
        <span class="balance-label">포인트</span>
        <span class="balance-value">{{ formatNumber(currentPoints) }}</span>
      </div>
      <div class="balance-item">
        <span class="balance-label">콤프</span>
        <span class="balance-value">{{ formatNumber(currentComps) }}</span>
      </div>
      <div class="balance-item">
        <span class="balance-label">게임머니</span>
        <span class="balance-value">{{ formatNumber(store.state.balance) }}</span>
      </div>
    </div>
    <div class="exchange-section">
      <div class="form-group">
        <label for="exchangeType">환전 유형</label>
        <select id="exchangeType" v-model="selectedType" class="form-control">
          <option value="points">포인트 → 게임머니</option>
          <option value="comps">콤프 → 게임머니</option>
        </select>
      </div>
      <div class="form-group">
        <label :for="selectedType">환전할 {{ selectedType === 'points' ? '포인트' : '콤프' }} 금액</label>
        <div class="bet-amount-container">
          <div class="cash-updown">
            <button class="down button" @click="decreaseExchangeAmount">-</button>
            <button class="up button" @click="increaseExchangeAmount">+</button>
          </div>
          <input 
            :id="selectedType"
            v-model="formattedExchangeAmount"
            @input="updateExchangeAmount"
            type="text"
            inputmode="numeric"
            placeholder="환전 금액"
            class="input amount"
          >
          <button @click="setFullAmount" class="btn-full-amount">전액</button>
        </div>
      </div>
      <div class="exchange-info">
        <div class="info-item">
          <span class="info-label">환전 금액</span>
          <span class="info-value">{{ formatNumber(exchangeAmount) }} {{ selectedType === 'points' ? '포인트' : '콤프' }}</span>
        </div>
        <div class="info-item">
          <span class="info-label">환전 후 게임머니</span>
          <span class="info-value">{{ formatNumber(store.state.balance + exchangeAmount) }}</span>
        </div>
      </div>
      <button @click="executeExchange" class="btn-exchange" :disabled="!isExchangeValid">
        환전하기
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'MoneyExchange',
  setup() {
    const store = useStore();
    const currentPoints = ref(10000);
    const currentComps = ref(5000);


    const selectedType = ref('points');
    const exchangeAmount = ref(0);

    const formattedExchangeAmount = computed({
      get: () => exchangeAmount.value.toLocaleString(),
      set: (value) => {
        exchangeAmount.value = Number(value.replace(/[^0-9]/g, ''));
      }
    });

    const isExchangeValid = computed(() => {
      if (selectedType.value === 'points') {
        return exchangeAmount.value > 0 && exchangeAmount.value <= currentPoints.value;
      } else {
        return exchangeAmount.value > 0 && exchangeAmount.value <= currentComps.value;
      }
    });

    const setFullAmount = () => {
      exchangeAmount.value = selectedType.value === 'points' ? currentPoints.value : currentComps.value;
    };

    const increaseExchangeAmount = () => {
      exchangeAmount.value += 10000;
    };

    const decreaseExchangeAmount = () => {
      if (exchangeAmount.value > 10000) {
        exchangeAmount.value -= 10000;
      }
    };

    const updateExchangeAmount = (event) => {
      const value = event.target.value.replace(/[^0-9]/g, '');
      exchangeAmount.value = Number(value);
    };

    const executeExchange = () => {
      if (!isExchangeValid.value) return;

      if (selectedType.value === 'points') {
        currentPoints.value -= exchangeAmount.value;
      } else {
        currentComps.value -= exchangeAmount.value;
      }
      store.state.balance.value += exchangeAmount.value;

      alert(`${exchangeAmount.value} ${selectedType.value === 'points' ? '포인트' : '콤프'}가 성공적으로 환전되었습니다.`);
      exchangeAmount.value = 0;
    };

    const formatNumber = (num) => {
      return new Intl.NumberFormat('ko-KR').format(num);
    };

    return {
      store,
      currentPoints,
      currentComps,
      selectedType,
      exchangeAmount,
      formattedExchangeAmount,
      isExchangeValid,
      setFullAmount,
      increaseExchangeAmount,
      decreaseExchangeAmount,
      updateExchangeAmount,
      executeExchange,
      formatNumber
    };
  }
};
</script>

<style scoped>
.money-exchange {
  max-width: 100vw;  /* 모바일 기본값 */
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
}

@media (min-width: 769px) {
  .money-exchange {
    max-width: 500px;  /* PC 환경 */
  }
}

.balance-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.balance-item {
  text-align: center;
}

.balance-label {
  display: block;
  font-size: 0.9em;
  color: #b0b0b0;
  margin-bottom: 5px;
}

.balance-value {
  font-size: 1.1em;
  font-weight: bold;
  color: #ffffff;
}

.exchange-section {
  background: transparent;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #d0d0d0;
}

.bet-amount-container {
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cash-updown {
  display: flex;
}

.button {
  background: linear-gradient(145deg, #2c3e50, #34495e);
  border: none;
  color: white;
  padding: 0 12px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.button:hover {
  background: linear-gradient(145deg, #34495e, #2c3e50);
}

.down {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.up {
  border-radius: 0;
}

.input.amount {
  flex-grow: 1;
  height: 40px;
  background-color: #2c3e50;
  border: none;
  color: white;
  text-align: right;
  padding: 0 10px;
  font-size: 16px;
}

.input.amount::placeholder {
  color: #7f8c8d;
}

.btn-full-amount {
  padding: 0 15px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-full-amount:hover {
  background: linear-gradient(145deg, #0056b3, #007bff);
}

.exchange-info {
  margin-top: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info-label {
  color: #b0b0b0;
}

.info-value {
  font-weight: bold;
  color: #ffffff;
}

.btn-exchange {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-exchange:hover:not(:disabled) {
  background: linear-gradient(145deg, #0056b3, #007bff);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn-exchange:disabled {
  background: linear-gradient(145deg, #4a4a4a, #3a3a3a);
  cursor: not-allowed;
}

select.form-control {
  width: 100%;
  padding: 10px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px auto;
}

@media (max-width: 480px) {
  .money-exchange {
    padding: 15px;
  }

  .balance-summary {
    flex-direction: column;
    align-items: stretch;
  }

  .balance-item {
    margin-bottom: 10px;
  }

  .input.amount, .btn-full-amount, .btn-exchange {
    font-size: 0.9em;
  }
}
</style>


