<template>
  <div class="cart">
    <ul>
      <li v-for="(option, index) in selectedOptions" :key="index">
        <div class="game-info">
          <span class="game-title">
            <img src="./../assets/minigame/ntry.logo.png" class="category-logo-img" alt="logo">
            {{ findGameById(5).game_name }} {{gameDetails.roundInfo.nextRound}}회차
          </span>
          <span class="game-close" @click="removeOption(index)">x</span>
        </div>
        <div class="game-type">
          <span class="mini-game-label">(미니게임)</span>
          <span class="game-details">{{selectedOptions[0].type}}</span>
        </div>
        <div class="bet-details">
          <span class="bet-option">{{option.text}}</span>
          <span class="bet-odds">x{{ totalOdds.toFixed(2) }}</span>
        </div>
      </li>
    </ul>
    <div v-if="selectedOptions.length > 0">
      <div class="bet-amount-container">
        <div class="cash-updown">
          <button class="down button" @click="decreaseBetAmount" :disabled="gameDetails.gamemoney <= 0">-</button>
          <button class="up button" @click="increaseBetAmount" :disabled="betAmount >= gameDetails.gamemoney">+</button>
        </div>
        <input 
          :value="formattedBetAmount" 
          @input="updateBetAmount" 
          type="text" 
          inputmode="numeric"
          placeholder="베팅금액" 
          class="input amount"
        >
      </div>
      <p v-if="showWarning" class="warning-message">{{ showWarning }}</p>
      <div class="total-odds">
        <span class="label">전체 배당</span>
        <span class="value">x{{ totalOdds.toFixed(2) }}</span>
      </div>
      <div class="potential-win">
        <span class="label">예상당첨 금액</span>
        <span class="value">₩{{ potentialWin.toLocaleString() }}</span>
      </div>
      <button class="betting-submit" @click="handleBettingSubmit" :disabled="isLoading">
        {{ isLoading ? '처리 중...' : '배팅완료' }}
      </button>
      <div v-if="error" class="error-message">
        {{ error }}
      </div>
    </div>
    <span v-else>
      <img src="./../assets/img/pause.svg" class="pause-logo" alt="Empty cart">
      카트가 비어있습니다
    </span>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

export default {
  name: 'CartBasic',
  props: {
    selectedOptions: {
      type: Array,
      required: true,
    },
    gametype: {
      type: String,
      required: true,
    },
    round: {
      type: Number,
      required: false,
    },
    gameDetails: {
      type: Object,
      required: false,
    },
    game_id: {
      type: Number,
      required: false,
    },
  },
  emits: ['update:selectedOptions', 'update:gameDetails',],
  setup(props, { emit }) {
    const store = useStore();
    const betAmount = ref(0);
    const showWarning = ref(null);
    const isLoading = ref(false);
    const error = ref(null);
    const successMessage = ref(null);
    

    const findGameById = (id) => {
      if (props.gameDetails && props.gameDetails.game && props.gameDetails.game.game_list) {
        return props.gameDetails.game.game_list.find(game => game.game_id === id);
      }
      return null;
    };

    const checkMinigameAmountLimit = (newAmount) => {
      if (!props.selectedOptions.length) {
        return null;
      }
      // 배팅당 한도
      const select_key = props.selectedOptions[0].key;
      const selectLimit = props.gameDetails.betting_setting.find(obj => Object.prototype.hasOwnProperty.call(obj, select_key));
      if (selectLimit && selectLimit[select_key].max_betting_per_bet < newAmount) {
        return `${findGameById(3).game_name} 배팅 한도초과 ${selectLimit[select_key].max_betting_per_bet}원`;
      }

      // 라운드당 한도
      const roundLimit = props.gameDetails.betting_setting.find(obj => Object.prototype.hasOwnProperty.call(obj, 'ROUND'));
      if (roundLimit && roundLimit.ROUND.max_betting_per_round < newAmount) {
        return `라운드당 배팅 한도초과 ${roundLimit.ROUND.max_betting_per_round}원`;
      }

      return null;
    };

    const formattedBetAmount = computed(() => {
      return betAmount.value.toLocaleString();
    });

    const totalOdds = computed(() => {
      return props.selectedOptions.reduce((total, option) => total * option.odds, 1);
    });

    const potentialWin = computed(() => {
      return betAmount.value * totalOdds.value;
    });

    const removeOption = (index) => {
      const updatedOptions = [...props.selectedOptions];
      updatedOptions.splice(index, 1);
      emit('update:selectedOptions', updatedOptions);
      betAmount.value = 0
      showWarning.value = null
      error.value = null
      successMessage.value = null

    };

    const increaseBetAmount = () => {
      if (betAmount.value + 10000 <= store.state.balance) {
        betAmount.value += 10000;
      } else {
        betAmount.value = store.state.balance
      }
    };

    const decreaseBetAmount = () => {
      if (betAmount.value >= 10000) {
        betAmount.value -= 10000;
      } else {
        betAmount.value = 0;
      }
    };

    const updateBetAmount = (event) => {
      const value = event.target.value.replace(/[^0-9]/g, '');
      let newAmount = Number(value);
      if (newAmount > store.state.balance) {
        newAmount = store.state.balance;
      }
      betAmount.value = newAmount;
    };

     const canSubmitBet = computed(() => {
      return betAmount.value > 0 && 
             betAmount.value <= store.state.balance && 
             props.selectedOptions.length > 0 && 
             !showWarning.value;
      });

    const handleBettingSubmit = async () => {
      if (!canSubmitBet.value) {
        error.value = '베팅을 완료할 수 없습니다. 베팅 금액과 선택 사항을 확인해주세요.';
        return;
      }

      isLoading.value = true;
      error.value = null;
      try {
        await axios.post(`https://goldfish-app-cklru.ondigitalocean.app/api/games/mini/debit`, 
        { 
          data: {
            category: Number(props.gameDetails.game.category),
            prd_id: Number(props.gameDetails.game.prd_id),
            game_id: Number(props.game_id),
            betting_detail: [{ [props.selectedOptions[0].key]: `${props.selectedOptions[0].value}` }],
            betting_amount: betAmount.value,
          }
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.jwt}`,
          },
        });

        // 성공적으로 배팅이 완료된 경우
        alert('배팅이 성공적으로 완료되었습니다.');
        emit('update:selectedOptions', []);
        betAmount.value = 0;

      } catch (err) {
        handleBettingError(err);
      } finally {
        isLoading.value = false;
      }
    };

    const handleBettingError = (err) => {
      if (!store.state.user || !store.state.user.id) {
        error.value = '로그인이 만료되었습니다. 다시 로그인해주세요.';
        store.dispatch('logout');
        return;
      }
      if (err.response && err.response.status === 401) {
        error.value = '로그인이 필요합니다.';
        store.dispatch('logout');
      } else if (err.response && err.response.status === 400) {
        if (err.response.data.error.message === "구멍당 최대베팅금액 초과입니다" 
        || err.response.data.error.message === "회차당 최대베팅횟수 초과입니다"
        || err.response.data.error.message === "요청액이 잔액을 초과합니다"
        ) {
          error.value = err.response.data.error.message;
        }else {
          error.value = '배팅 중 오류가 발생했습니다.';
        }
      } else {
        console.error('Error placing bet:', err);
        error.value = err.message || '알 수 없는 오류가 발생했습니다.';
      }
    };

    watch(() => betAmount.value, (newAmount) => {
      showWarning.value = checkMinigameAmountLimit(newAmount);
    });

    return {
      betAmount,
      showWarning,
      formattedBetAmount,
      totalOdds,
      potentialWin,
      removeOption,
      increaseBetAmount,
      decreaseBetAmount,
      updateBetAmount,
      handleBettingSubmit,
      isLoading,
      error,
      successMessage,
      findGameById,
      canSubmitBet,
    };
  }
};
</script>
<style scoped>

.game-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;  /* 글자 크기를 줄입니다. 필요에 따라 조정하세요. */
  margin-bottom: 5px;
}

.category-logo-img {
  width: 17px;
  margin-right: 5px;
}

.game-title {
  /* flex-grow: 1; */
  text-align: right;
  font-size: 0.8rem;
  font-weight: bold;
    color: #fff;
}
.game-close {
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}
.game-type {
  font-size: 0.9em;
  margin-bottom: 5px;
}

.mini-game-label {
  color: #007bff;  /* 파란색 예시, 원하는 색상으로 변경 가능 */
  font-weight: bold;
  margin-right: 5px;
}

.game-details {
  color: #fff;  /* 텍스트 색상, 필요에 따라 조정 */
}
.cart > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 150px; /* 필요에 따라 조정하세요 */
}
.pause-logo {
  width: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
  padding: 5px;
  /* border: 1px solid #eee; */
  /* border-radius: 4px; */
}

button {
  margin-left: 10px;
  padding: 2px 5px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

input {
  width: 100%;
  padding: 5px;
  margin-top: 10px;
}
.category-logo-img{
  width:17px;
}
.bet-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  margin-top: 5px;
  padding: 5px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.bet-option {
  color: #fff;
  font-weight: bold;
}

.bet-odds {
  color: #007bff;  /* 금색으로 배당률 강조 */
  font-weight: bold;
}
.bet-amount-container {
  display: flex;
  margin-top: 10px;
  align-items: flex-end;
}

.cash-updown {
  display: flex;
  flex-direction: row;
}

.button {
  background-color: #2c3e50;
  border: none;
  color: white;
  padding: 0 10px;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background-color: #34495e;
}

.down {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.up {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input.amount {
  flex-grow: 1;
  height: 40px;
  background-color: #303a46;
  border: none;
  border-left: 1px solid #38424e;
  color: white;
  text-align: right;
  padding: 0 10px;
  font-size: 16px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.input.amount::placeholder {
  color: #6c7a89;
}
.total-odds {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 15px;
}

.total-odds .label {
  font-size: 0.9em;
  color: #ffffff;
  font-weight: bold;
}

.total-odds .value {
  font-size: 0.9em;
  font-weight: bold;
  color: #007bff;  /* 금색으로 배당률 강조 */
}
.potential-win {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;
}

.potential-win .label {
  font-size: 0.9em;
  color: #ffffff;
  font-weight: bold;
}

.potential-win .value {
  font-size: 0.9em;
  font-weight: bold;
  color: #007bff;  /* 녹색으로 당첨 금액 강조 */
}

.warning-message {
  color: #ff0000;
  font-size: 0.8em;
  margin-top: 5px;
  text-align: right;
}
.success-message {
  color: #4CAF50;
  font-size: 0.9em;
  margin-top: 10px;
  text-align: center;
}

.error-message {
  color: #f44336;
  font-size: 0.9em;
  margin-top: 10px;
  text-align: center;
}

.betting-submit {
  width: 100%;
  padding: 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 15px;
}

.betting-submit:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
</style>