<template>
  <div class="world-sport-page" :key="componentKey">    
    <div class="sport-layout">
      <div class="main-content-wrapper">
        <div class="sport-table-container">
          <div class="main-content mt-4">
            <!-- 카테고리 부분 -->
            <div v-if="loading">Loading...</div>
            <div v-else-if="error">Error: {{ error }}</div>
            <div v-else>
              <div class="category-tabs">
                <button 
                  v-for="category in sub_category" 
                  :key="category.id"
                  @click="selectCategory(category)"
                  :class="{ active: selectedCategory.id === category.id }"
                > 
                  <span v-if="category.prd_name == 'football'">
                    <img src="@/assets/sports/football.svg" class="sportsymball category-icon">
                    <p>축구</p>
                  </span>
                  <span v-if="category.prd_name == 'afl'">
                    <img src="@/assets/sports/afl.svg" class="sportsymball category-icon">
                    <p>호식축구</p>
                  </span>
                  <span v-if="category.prd_name == 'baseball'">
                    <img src="@/assets/sports/baseball.svg" class="sportsymball category-icon">
                    <p>야구</p>
                  </span>
                  <span v-if="category.prd_name == 'basketball'">
                    <img src="@/assets/sports/basketball.svg" class="sportsymball category-icon">
                    <p>농구</p>
                  </span>
                  <span v-if="category.prd_name == 'handball'">
                    <img src="@/assets/sports/handball.svg" class="sportsymball category-icon">
                    <p>핸드볼</p>
                  </span>
                  <span v-if="category.prd_name == 'formula'">
                    <img src="@/assets/sports/formula.png" class="sportsymball category-icon">
                    <p>formula</p>
                  </span>
                  <span v-if="category.prd_name == 'hockey'">
                    <img src="@//assets/sports/hockey.svg" class="sportsymball category-icon">
                    <p>하키</p>
                  </span>
                  <span v-if="category.prd_name == 'volleyball'">
                    <img src="@//assets/sports/volleyball.svg" class="sportsymball category-icon">
                    <p>배구</p>
                  </span>
                  <span v-if="category.prd_name == 'rugby'">
                    <img src="@//assets/sports/rugby.svg" class="sportsymball category-icon">
                    <p>rugby</p>
                  </span>
                  <span v-if="category.prd_name == 'nfl'">
                    <img src="@//assets/sports/nfl.svg" class="sportsymball category-icon">
                    <p>nfl</p>
                  </span>
                  <span v-if="category.prd_name == 'mma'">
                    <img src="@//assets/sports/mma.svg" class="sportsymball category-icon">
                    <p>MMA</p>
                  </span>
                </button>
              </div>
              <div class="search-container">
                <input 
                  type="text" 
                  v-model="searchQuery" 
                  @input="handleSearch" 
                  placeholder="팀 또는 리그 검색..." 
                  class="search-input"
                />
                <button @click="handleSearch" class="search-button">
                  검색
                </button>
              </div>
            </div>
            <!-- 테이블 부분 -->
            <div v-if="display" class="matches-list">
              <div v-if="Object.keys(groupedMatches).length === 0">No matches found for this category.</div>
              <div v-else v-for="(leagueMatches, leagueName) in groupedMatches" :key="leagueName">
                <div class="league-header">
                  <span class="league-name">{{leagueName}}</span>
                </div>
                <table class="match-table">
                  <thead>
                    <tr>
                      <th class="date-time"></th>
                      <th class="teams">Teams</th>
                      <th class="market-header home-team-col">홈팀</th>
                      <th class="market-header draw-col">무승무</th>
                      <th class="market-header away-team-col">원정패</th>
                      <th class="market-header handicap-col">핸디캡</th>
                      <th class="market-header over-under-col">언오버</th>
                      <th class="more-bets">bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="match in leagueMatches" :key="match.id" class="match-row">
                      <td class="date-time">
                        <div class="date">{{formatDate(match.date).date}}</div>
                        <div class="time">{{formatDate(match.date).time}}</div>
                      </td>
                      <td class="teams">
                        <div class="home-team">{{match.home_team_name}}</div>
                        <div class="away-team">{{match.away_team_name}}</div>
                      </td>
                      <td v-for="(market, index) in ['Match Winner', 'Match Winner', 'Match Winner', 'Asian Handicap', 'Goals Over/Under']" :key="index" 
                          :class="['odds-cell', 
                                  index === 0 ? 'home-team-col' : 
                                  index === 1 ? 'draw-col' : 
                                  index === 2 ? 'away-team-col' : 
                                  index === 3 ? 'handicap-col' : 
                                  'over-under-col']">
                        <template v-if="match.my_odds && match.my_odds[market]">
                          <div v-if="index <= 2" class="odd-button" 
                               @click="selectOdd(match, market, match.my_odds[market].values[index])"
                               :class="{'selected-odd': isOddSelected(match, market, match.my_odds[market].values[index])}">
                            {{ match.my_odds[market].values[index].odd }}
                          </div>
                          <div v-else class="handicap-overunder">
                            <div v-for="(value, vIndex) in match.my_odds[market].values" :key="vIndex" 
                                 class="odd-button"
                                 @click="selectOdd(match, market, value)"
                                 :class="{'selected-odd': isOddSelected(match, market, value)}">
                                <span class="handicap" v-if="['Home', 'Away'].includes(extractNumber(value.value)[0])">
                                  {{ extractNumber(value.value)[1] }}
                                </span>
                                <span class="over" v-if="extractNumber(value.value)[0]=='Over'">
                                  {{ extractNumber(value.value)[1] }} 
                                </span>
                                <span class="under" v-else-if="extractNumber(value.value)[0]=='Under'">
                                  {{ extractNumber(value.value)[1] }} 
                                </span>
                                <span class="odd">{{ value.odd }}</span>
                            </div>
                          </div>
                        </template>
                      </td>
                      <td class="more-bets" @click="selectDetail(match.id)">+{{ match.odds_count }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 카트부분 -->
      <div class="cart-wrapper">
        <div class="cart-container mt-4">
          <CartBasic
            class="cart"
            :selected-options="selectedOptions"
            @update:selectedOptions="updateSelectedOptions"
            :gametype="'minigame'"
            :betAmount="betAmount"
            @update:betAmount="updateBetAmount"
          />
          <button @click="BettingSubmit" class="btn btn-primary w-100 mt-3">배팅완료</button>
        </div>
      </div>
    </div>
  </div>  
</template>


<script>
import { ref, onMounted, computed, nextTick, watch } from 'vue';
import axios from 'axios';
import CartBasic from '@/components/CartBasic.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default {
    name: 'WorldSport',
    components: {
        CartBasic,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const componentKey = ref(0);
        const display = ref(true) // true:list,  false:detail
        const matches = ref([]);
        
        const matchesDetail = ref([])
        const selectedOptions = ref([]);
        const betAmount = ref(0);
        const loading = ref(true);
        const error = ref(null);
        const selectedCategory = ref({ id: 4, name: "football" });

        const sub_category = ref([]);

        const searchQuery = ref('');
        const filteredMatches = ref([]);

        const handleSearch = () => {
          console.log('sdf')
          if (searchQuery.value.length > 0) {
            filteredMatches.value = matches.value.filter(match => 
              match.home_team_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
              match.away_team_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
              match.league.name.toLowerCase().includes(searchQuery.value.toLowerCase())
            );
          } else {
            filteredMatches.value = matches.value;
          }
        };

        const updateCategory = () => {
          sub_category.value = [];
          const category4Data = store.state.categorizedData[4];
          const sortedData = category4Data.sort((a, b) => a.order - b.order);
          sortedData.forEach((element) => {
           sub_category.value.push(element)
          })
        }

        const extractNumber = (value) => {
            if (typeof value === 'string') {
                const textMatch = value.match(/[a-zA-Z]+/);
                const numberMatch = value.match(/[+-]?\d+(\.\d+)?/);
                return [
                    textMatch ? textMatch[0] : '',
                    numberMatch ? numberMatch[0] : ''
                ];
            }
            return ['', ''];
        };

        const groupedMatches = computed(() => {
            const groups = {};
            matches.value.forEach(match => {
                const leagueName = match.league.name;
                if (!groups[leagueName]) {
                    groups[leagueName] = [];
                }
                groups[leagueName].push(match);
            });
            return groups;
        });

        const selectCategory = (category) => {
            selectedCategory.value = category;
            display.value = true
            fetchData();
        };

        const isOddSelected = (match, market, value) => {
          return selectedOptions.value.some(option => 
            option.game_id === match.id && 
            option.bets === market && 
            option.selection === value.value
          );
        };

        const selectDetail = async (id) => {
            const response = await axios.get(`http://localhost:1337/api/sports/detail/${id}`);
            matchesDetail.value = response.data
            display.value = false
        }
        
        const selectOdd = (match, market, value) => {
          const selection = {
              game_id: match.game_id || match.id,
              sport_id: match.id,
              text: `${match.home_team_name} vs ${match.away_team_name}`,
              name: `${market} ${value.value}`,
              odds: value.odd,
              match_info: {
                  home: match.home_team_name,
                  away: match.away_team_name
              },
              leagueName: match.league.name,
              date: match.date,
              bets: market,
              value: value.value,
              selection: value.value
          };

          if (selectedOptions.value.length >= 10) {
              const isAlreadySelected = selectedOptions.value.some(
                  option => option.game_id === selection.game_id && 
                            option.bets === selection.bets &&
                            option.selection === selection.selection
              );

              if (!isAlreadySelected) {
                  alert("최대 10개까지만 선택할 수 있습니다.");
                  return;
              }
          }

          const existingIndex = selectedOptions.value.findIndex(
              option => option.game_id === selection.game_id && 
                        option.bets === selection.bets
          );

          if (existingIndex !== -1) {
              // 같은 경기, 같은 마켓의 다른 옵션이 선택된 경우
              if (selectedOptions.value[existingIndex].selection !== selection.selection) {
                  selectedOptions.value[existingIndex] = selection;
              } else {
                  // 이미 선택된 옵션을 다시 클릭한 경우, 선택 해제
                  selectedOptions.value.splice(existingIndex, 1);
              }
          } else {
              selectedOptions.value.push(selection);
          }

          console.log('Updated selected options:', selectedOptions.value);
      };

        const backToList = () => {
            display.value = true
        }

        const updateSelectedOptions = (newOptions) => {
            selectedOptions.value = newOptions;
        };

        const updateBetAmount = (newAmount) => {
          betAmount.value = newAmount;
        };

        const fetchData = async () => {
            try {
                loading.value = true;
                error.value = null;
                const response = await axios.get(`http://localhost:1337/api/sports/list/${selectedCategory.value.id}`);
                matches.value = response.data;
            } catch (err) {
                console.error('Error fetching sport data:', err);
                error.value = err.message;
            } finally {
                loading.value = false;
            }
        };

        const formatDate = (date) => {
          if (!date) return { date: 'N/A', time: 'N/A' };
          const koreaTime = new Date(date);
          koreaTime.setHours(koreaTime.getHours() + 9);
          return {
            date: koreaTime.toLocaleDateString('en-US', {
              timeZone: 'Asia/Seoul',
              month: '2-digit',
              day: '2-digit'
            }).replace(/\//g, '/'),
            time: koreaTime.toLocaleTimeString('ko-KR', {
              timeZone: 'Asia/Seoul',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            })
          };
        };

        const BettingSubmit = async () => {
          try {
            await axios.post('http://localhost:1337/api/betting/sport', {
                detail : selectedOptions.value.map(option => ({
                  game_id: option.game_id,
                  sport_id: option.sport_id,
                  odds: option.odds,
                  bets: option.bets,
                  value: option.value,
              })),
              amount : betAmount.value,
              users_permissions_user: store.state.user.id,
            }, {
            headers: {
              Authorization: `Bearer ${store.state.jwt}`,
            },
          })
          selectedOptions.value = [];
          alert('배팅이 완료되었습니다.');
          } catch(error){
            handleBettingError(error)
          }
        };

        const handleBettingError = (error) => {
          if (!store.state.user || !store.state.user.id) {
            alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
            store.dispatch('logout');
            return;
          }
          if (error.response && error.response.status === 401) {
            alert('로그인이 필요합니다.');
            store.dispatch('logout');
          } else if (error.response && error.response.status === 400) {
            if (error.response.data.message === "Minigame betting can only include one item in detail.") {
              alert("미니게임은 한개의 폴더만 배팅 가능합니다");
            } else if (error.response.data.message === "Betting on round only this round") {
              alert("베팅은 오직 이번 라운드에만 가능합니다");
              window.location.reload();
            } else {
              alert('배팅 중 오류가 발생했습니다.');
            }
          } else {
            console.error('Error placing bet:', error);
            alert('배팅 중 오류가 발생했습니다.');
          }
        };

        const updateScroll = () => {
      nextTick(() => {
        window.scrollTo(0, 0);
        // 스크롤을 강제로 업데이트
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
          document.body.style.overflow = '';
        }, 0);
      });
    };

        const forceRerender = () => {
      componentKey.value += 1;
      nextTick(() => {
        updateScroll();
      });
    };

        onMounted(() => {
      fetchData();
      updateCategory();
      updateScroll();
    });

        watch(() => route.fullPath, (newPath, oldPath) => {
      if (newPath !== oldPath) {
        forceRerender();
      }
    });

        // 라우터 네비게이션 가드 추가
        router.beforeEach((to, from, next) => {
      if (to.name === 'WorldSport') {
        next();
        nextTick(() => {
          forceRerender();
        });
      } else {
        next();
      }
    });

        return {
            display,
            matches,
            matchesDetail,
            groupedMatches,
            selectedOptions,
            loading,
            error,
            formatDate,
            selectCategory,
            selectDetail,
            backToList,
            sub_category,
            selectOdd,
            betAmount,
            updateBetAmount,
            updateSelectedOptions,
            selectedCategory,
            BettingSubmit,
            searchQuery,
            handleSearch,
            filteredMatches,
            isOddSelected,
            extractNumber,
            updateScroll,
            componentKey,
            forceRerender
        };
    },
};
</script>



<style scoped>
.world-sport-page {
  background-color: #1a1a1a;
  color: white;
  width: 100%;
}

.sport-layout {
  display: flex;
  max-width: 100%;
}

.main-content-wrapper {
  flex: 1;
  padding-right: 20px;
  max-width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
}

.sport-table-container {
  flex-grow: 1;
}

.main-content {
  padding: 20px;
  background-color: #2a2a2a;
  box-shadow: 0 0 10px rgba(255,255,255,0.1);
}

.cart-wrapper {
  width: 300px;
  padding-left: 20px;
  position: sticky;
  top: 0;
  align-self: flex-start;
}

.cart-container {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 8px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
}


.category-tabs {
  display: flex;
  margin-bottom: 10px;
  overflow-x: auto;
  padding: 3px 0 5px;
  gap: 10px;
}

.category-tabs button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  border: none;
  background-color: #333333;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 12px;
  transition: all 0.3s ease;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 50px;
  width: 80px;
}

.category-tabs button:hover {
  background-color: #444444;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.category-tabs button.active {
  background: linear-gradient(145deg, #0056b3, #007bff);
  box-shadow: 0 2px 10px rgba(0, 123, 255, 0.5);
}

.category-icon {
  width: 20px;
  height: auto;
  margin-bottom: 5px;
}

.search-container {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

.search-input {
  flex-grow: 1;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  background-color: #444444;
  color: white;
  font-size: 14px;
}

.search-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.match-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  table-layout: fixed;
}

.match-table th,
.match-table td {
  padding: 8px 5px;
  text-align: center;
  border: 1px solid #3a3a3a;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.match-table th {
  background-color: #2a2a2a;
  font-weight: bold;
}

.match-row:hover {
  background-color: #4d324d;
}

.date-time {
  width: 70px;
  font-size: 0.75em;
}

.date-time .date {
  font-size: 0.9em;
  color: #cccccc;
}

.date-time .time {
  font-size: 1em;
  font-weight: bold;
}

.teams {
  width: 130px;
  text-align: left;
  padding-left: 10px;
}

.home-team, .away-team {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8em;
  line-height: 1.1em;
  text-align: left;
}

.home-team {
  margin-bottom: 2px;
}

.home-team-col {
  width: 60px;
}

.draw-col {
  width: 50px;
}

.away-team-col {
  width: 60px;
}

.handicap-col {
  width: 120px;
}

.over-under-col {
  width: 120px;
}

.more-bets {
  width: 40px;
  cursor: pointer;
  color: #4da6ff;
  font-size: 0.75em;
}

.odd-button {
  cursor: pointer;
  padding: 4px 2px;
  border-radius: 4px;
  background-color: #444444;
  color: white;
  font-size: 0.85em;
  transition: background-color 0.3s ease;
}

.odd-button:hover {
  background-color: #555555;
}

.selected-odd {
  background-color: #28a745;
  color: black;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(40, 167, 69, 0.5);
  transform: scale(1.05);
  transition: all 0.3s ease;
}

.selected-odd:hover {
  background-color: #218838;
}

.handicap-overunder {
  display: flex;
  justify-content: space-between;
}

.handicap-overunder .odd-button {
  width: 48%;
  font-size: 0.8em;
}

.handicap-overunder .handicap {
  color: rgb(255, 167, 77);
  font-size: 0.7rem;
  text-align: left;
}

.handicap-overunder .over {
  color: blue;
  font-size: 0.7rem;
  text-align: left;
}

.handicap-overunder .under {
  color: red;
  font-size: 0.7rem;
  text-align: left;
}

.handicap-overunder .odd {
  font-size: 0.75rem;
}

.league-header {
  background-color: #2a2a2a;
  padding: 10px;
  margin-top: 20px;
  border-radius: 8px 8px 0 0;
}

@media (max-width: 1200px) {
  .sport-layout {
    flex-direction: column;
  }

  .main-content-wrapper {
    padding-right: 0;
    max-width: 100%;
  }

  .cart-wrapper {
    width: 100%;
    padding-left: 0;
    position: static;
  }
}

@media (max-width: 768px) {
  .category-tabs {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  
  .category-tabs button {
    flex: 0 0 auto;
  }

  .odds-cell {
    width: 30px;
  }
  
  .wide-cell {
    width: 200px;
  }

  .match-table th.handicap-col,
  .match-table td.handicap-col,
  .match-table th.over-under-col,
  .match-table td.over-under-col {
    display: none;
  }

  .match-table {
    table-layout: auto;
  }

  .teams {
    width: auto;
  }

  .date-time {
    width: 60px;
  }

  .more-bets {
    width: 30px;
  }
}
</style>



