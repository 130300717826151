<template>
  <div :class="modalClass" id="loginModal" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
    <div :class="dialogClass">
      <div class="modal-content bg-dark text-light">
        <div class="modal-body">
          <button type="button" class="btn-close btn-close-white float-end" @click="closeModal" aria-label="Close"></button>
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item" v-for="tab in tabs" :key="tab.value">
              <button class="nav-link" :class="{ active: currentTab === tab.value }" @click="selectTab(tab.value)">
                {{ tab.name }}
              </button>
            </li>
          </ul>
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div v-if="currentTab === 'login'" class="login-content">
              <LoginForm />
            </div>
            <div v-else-if="currentTab === 'register'" class="register-content">
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import LoginForm from '@/components/community/LoginForm.vue';
import RegisterForm from '@/components/community/RegisterForm.vue';

export default {
  name: 'LoginModal',
  components: {
    LoginForm,
    RegisterForm,
  },
  props: {
    selectedTab: {
      type: String,
      default: 'login'
    }
  },
  setup(props) {
    const store = useStore();
    let modalInstance = null;

    const tabs = ref([
      { name: '로그인', value: 'login' },
      { name: '회원가입', value: 'register' },
    ]);

    const isLoading = ref(false);
    const currentTab = computed(() => store.state.loginModalTab);

    const isMobile = computed(() => store.state.isMobile);

    const modalClass = computed(() => {
      return isMobile.value ? 'modal fade full-screen-modal' : 'modal fade';
    });

    const dialogClass = computed(() => {
      return isMobile.value ? 'modal-dialog modal-fullscreen' : 'modal-dialog modal-md';
    });

    const selectTab = (tab) => {
      store.commit('SET_LOGIN_MODAL_TAB', tab);
    };

    watch(() => props.selectedTab, (newTab) => {
      if (newTab) {
        selectTab(newTab);
      }
    }, { immediate: true });

    onMounted(() => {
      const modalElement = document.getElementById('loginModal');
      if (modalElement) {
        modalInstance = new window.bootstrap.Modal(modalElement, {
          backdrop: 'static',
          keyboard: true
        });

        modalElement.addEventListener('hidden.bs.modal', () => {
          store.commit('SET_LOGIN_MODAL_OPEN', false);
        });
      }
      
      watch(() => store.state.loginModalOpen, (newValue) => {
        if (newValue && modalInstance) {
          modalInstance.show();
        } else if (modalInstance) {
          modalInstance.hide();
        }
      }, { immediate: true });
    });

    onUnmounted(() => {
      if (modalInstance) {
        modalInstance.dispose();
      }
      const modalElement = document.getElementById('loginModal');
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', () => {
          store.commit('SET_LOGIN_MODAL_OPEN', false);
        });
      }
    });

    const closeModal = () => {
      if (modalInstance) {
        modalInstance.hide();
        modalInstance._element.addEventListener('hidden.bs.modal', () => {
          store.commit('SET_LOGIN_MODAL_OPEN', false);
        }, { once: true });
      } else {
        store.commit('SET_LOGIN_MODAL_OPEN', false);
      }
    };

    return {
      tabs,
      currentTab,
      selectTab,
      isLoading,
      isMobile,
      modalClass,
      dialogClass,
      closeModal
    };
  },
};
</script>

<style scoped>
.modal-md {
  max-width: 400px;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  color: #fff;
  background-color: transparent;
  border-bottom: 2px solid #007bff;
}

.login-content,
.register-content {
  padding: 1rem 0;
}

.full-screen-modal .modal-dialog {
  margin: 0;
  padding: 0;
}

.full-screen-modal .modal-content {
  min-height: 100vh;
  border: none;
  border-radius: 0;
}

@media (max-width: 768px) {
  .modal-md {
    max-width: 100%;
  }
}
</style>