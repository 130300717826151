// DONE

// 소켓
import io from "socket.io-client";

export const socket = io.connect(process.env.VUE_APP_API_URL, {
  path: "/socket.io",
  transports: ["websocket"],
  autoConnect: false,
});

export const reconnect = () => {
  return new Promise((resolve, reject) => {
    if (socket.connected) {
      return resolve();
    }

    const onConnect = () => {
      socket.off("connect", onConnect);
      socket.off("connect_error", onError);
      resolve();
    };

    const onError = (error) => {
      socket.off("connect", onConnect);
      socket.off("connect_error", onError);
      reject(error);
    };

    socket.on("connect", onConnect);
    socket.on("connect_error", onError);

    socket.connect();
  });
};

// 쿠키
import Cookies from "js-cookie";

export const cookies = Cookies.withAttributes({
  // TODO
  // domain: "",
  path: "/",
  expires: 1,
  secure: true,
  sameSite: "strict",
});

// 암호화
export const fakeEncrypt = (param) => {
  let toCode = [];
  let toChar = "";

  for (let i = 0; i < param.length; i++) {
    toCode.push(param[i].charCodeAt());
  }

  toCode = toCode.reverse();

  for (let i = 0; i < toCode.length; i++) {
    toChar += String.fromCharCode(toCode[i] + i);
  }

  return btoa(toChar);
};

// 복호화
export const fakeDecrypt = (param) => {
  let toCode = [];
  let toChar = "";

  param = atob(param);

  for (let i = 0; i < param.length; i++) {
    toCode.push(param[i].charCodeAt());
  }

  for (let i = 0; i < toCode.length; i++) {
    toCode[i] = toCode[i] - i;
  }

  toCode = toCode.reverse();

  for (let i = 0; i < toCode.length; i++) {
    toChar += String.fromCharCode(toCode[i]);
  }

  return toChar;
};

// 모달
import Swal from "sweetalert2";

export const errorSwal = (text) => {
  Swal.fire({
    icon: "error",
    title: "Error",
    text: text,
    confirmButtonText: "확인",
    backdrop: false,
  });
};

export const warningSwal = async (text) => {
  const result = await Swal.fire({
    icon: "warning",
    title: "Warning",
    text: text,
    confirmButtonText: "확인",
    showCancelButton: true,
    cancelButtonText: "취소",
    backdrop: false,
  });

  return result.isConfirmed;
};

export const successSwal = (text) => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: text,
    confirmButtonText: "확인",
    backdrop: false,
  });
};

// 토스트
import $ from "jquery";

export const toast = (body) => {
  $(document).Toasts("create", {
    class: "bg-info",
    icon: "fas fa-exclamation fa-fw",
    title: "Info",
    body: body,
  });
};

// 헤더
export const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    ...(cookies.get("accessToken") && {
      Authorization: `bearer ${cookies.get("accessToken")}`,
    }),
  };
};

// 팝업 열기
export const openPopup = (url, width = 1200, height = 800) => {
  const top = (screen.height - height) / 3;
  const left = (screen.width - width) / 2;
  const config = `top=${top},left=${left},width=${width},height=${height}`;

  window.open(url, "_blank", config);
};

// 팝업 닫기
export const closePopup = () => {
  window.close();
};

// 한국 시간
export const getKoreaHour = () => {
  const datetime = new Date().toISOString();
  const utc = new Date(datetime);
  const korea = new Date(utc.getTime() + 9 * 60 * 60 * 1000);

  return korea.toISOString().split("T")[1].split(".")[0];
};

// 한국 자정
export const getKoreaDate = (date) => {
  const datetime = new Date().toISOString();
  const utc = new Date(datetime);
  const korea = new Date(utc.getTime() + 9 * 60 * 60 * 1000);

  korea.setUTCDate(korea.getUTCDate() + Number(date));
  korea.setUTCHours(23, 59, 59, 999);

  return korea.toISOString().split("T")[0];
};

// 한국 월초
export const getKoreaMonth = () => {
  const datetime = new Date().toISOString();
  const utc = new Date(datetime);
  const korea = new Date(utc.getTime() + 9 * 60 * 60 * 1000);

  korea.setUTCDate(1);
  korea.setUTCHours(0, 0, 0, 0);
  korea.setUTCHours(korea.getUTCHours() - 9);

  return korea.toISOString();
};

// 숫자 변환
export const getNumberToString = (params) => {
  // 숫자
  if (typeof params === "number") {
    return params.toLocaleString("ko-KR", {
      maximumFractionDigits: 0,
    });
  }

  // 문자
  else if (typeof params === "string") {
    return Number(params.replace(/[^0-9]/gi, "")).toLocaleString("ko-KR", {
      maximumFractionDigits: 0,
    });
  }

  // 기타
  else {
    return params;
  }
};

// 계좌 형식
export const getAccountFormat = (account) => {
  if (typeof account === "string") {
    account = account.replace(/[^0-9]/gi, "");

    return account.replace(/(\d{4})(?=(\d)+\b)/g, "$1 ");
  } else {
    return account;
  }
};

// 모바일 형식
export const getMobileFormat = (mobile) => {
  if (typeof mobile === "string") {
    mobile = mobile.replace(/[^0-9]/gi, "");

    switch (mobile.length) {
      case 9:
        return mobile.replace(/^(\d{2})(\d{3})(\d{4})$/, `$1-$2-$3`);
      case 10:
        if (mobile.startsWith("02")) {
          return mobile.replace(/^(\d{2})(\d{4})(\d{4})$/, `$1-$2-$3`);
        } else {
          return mobile.replace(/^(\d{3})(\d{3})(\d{4})$/, `$1-$2-$3`);
        }
      case 11:
        return mobile.replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`);
      case 12:
        return mobile.replace(/^(\d{4})(\d{4})(\d{4})$/, `$1-$2-$3`);
      default:
        return mobile;
    }
  } else {
    return mobile;
  }
};

// 생일 형식
export const getBirthdateFormat = (birthdate) => {
  if (typeof birthdate === "string") {
    birthdate = birthdate.replace(/[^0-9]/gi, "");

    switch (birthdate.length) {
      case 8:
        return birthdate.replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`);
      default:
        return birthdate;
    }
  } else {
    return birthdate;
  }
};

// 시간 형식
export const getTimeFormat = (datetime) => {
  if (typeof datetime === "string") {
    const utc = new Date(datetime);
    const korea = new Date(utc.getTime() + 9 * 60 * 60 * 1000);

    return korea.toISOString().split("T")[1].slice(0, 5);
  } else {
    return datetime;
  }
};

// 날짜 형식
export const getDatetimeFormat = (datetime) => {
  if (typeof datetime === "string") {
    const utc = new Date(datetime);
    const korea = new Date(utc.getTime() + 9 * 60 * 60 * 1000);

    return korea.toISOString().split(".")[0].replace("T", " ");
  } else {
    return datetime;
  }
};

// IP 체크
export const isIPFormat = (ip) => {
  if (typeof ip === "string") {
    return ip.match(
      /(^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$)/
    );
  } else {
    return false;
  }
};
