<template>
  <div class="bonus-history">
    <h2 class="text-center mb-4">포인트 내역</h2>
    <div class="bonus-summary">
      <div class="summary-item">
        <span class="summary-label">총 받은 포인트</span>
        <span class="summary-value">{{ formatNumber(totalBonus) }} 원</span>
      </div>
      <div class="summary-item">
        <span class="summary-label">사용 가능한 포인트</span>
        <span class="summary-value">{{ formatNumber(availableBonus) }} 원</span>
      </div>
    </div>
    <div class="bonus-table-container">
      <table class="bonus-table">
        <thead>
          <tr>
            <th>날짜</th>
            <th>포인트 유형</th>
            <th>금액</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bonus in bonuses" :key="bonus.id">
            <td>{{ formatDate(bonus.date_res) }}</td>

            <!-- TODO -->
            <td v-if="bonus.type==3">관리자지급</td>
            <td v-else>관리자회수</td>

            <td v-if="bonus.type==3">{{ formatNumber(bonus.amount) }} 원</td>
            <td v-else>-{{ formatNumber(bonus.amount) }} 원</td>
            <td>
              {{ bonus.state }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">&lt; 이전</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">다음 &gt;</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
export default {
  name: 'PointList',
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const totalBonus = ref(0);
    const availableBonus = ref(50000);
    // const serverParams =  {
    //   columnFilters: {},
    //   page: 1,
    //   perPage: 5,
    //   sort: [],
    // };
    const currentPage = ref(1);
    const itemsPerPage = 10;
    
    const bonuses = ref([]);

    const totalPages = computed(() => Math.ceil(bonuses.value.length / itemsPerPage));

    const paginatedBonuses = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return bonuses.value.slice(start, end);
    });

    const prevPage = () => {
      if (currentPage.value > 1) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) currentPage.value++;
    };

    const formatNumber = (num) => {
      return new Intl.NumberFormat('ko-KR').format(num);
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear().toString().substr(-2);
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const fetchPoint = async () => {
      isLoading.value = true;

      try {
        const response = await axios.get(`https://goldfish-app-cklru.ondigitalocean.app/api/points?`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `bearer ${store.state.jwt}`
          }
        });

        totalBonus.value = response.data.meta.summary.amount

        response.data.data.forEach(element => {
          const id = element.id
          const { amount,date_res,state,type } = element.attributes
          bonuses.value.push({
            id: id,
            amount: amount,
            date_res: date_res,
            state: state,
            type: type
          })
        });
        // console.log('coupon info:', response.data);
        // Here you can update the store or local state with the fetched data
        // For example: store.commit('UPDATE_USER_INFO', response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        // isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchPoint()
    })
    return {
      totalBonus,
      availableBonus,
      bonuses: paginatedBonuses,
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      formatNumber,
      formatDate
    };
  }
};
</script>

<style scoped>
.bonus-history {
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
}

.bonus-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #0f0f0f, #1a1a1a);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.summary-item {
  text-align: center;
}

.summary-label {
  display: block;
  font-size: 0.9em;
  color: #a0a0a0;
  margin-bottom: 5px;
}

.summary-value {
  font-size: 1.2em;
  font-weight: bold;
  color: #f0f0f0;
}

.bonus-table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.bonus-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.bonus-table th,
.bonus-table td {
  padding: 12px;
  text-align: left;
}

.bonus-table th {
  background-color: #1c2833;
  color: #f0f0f0;
  font-weight: bold;
}

.bonus-table tr {
  background-color: #1c2833;
}

.bonus-table tr:hover {
  background-color: #1c2833;
}

.bonus-table td {
  color: #d0d0d0;
}

.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: bold;
}

.status.사용가능 {
  background-color: #1a5928;
  color: #a0e8af;
}

.status.사용완료 {
  background-color: #1a4c68;
  color: #a0d8e8;
}

.status.만료됨 {
  background-color: #681a1a;
  color: #e8a0a0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 10px;
  background-color: #1c2833;
  color: #d0d0d0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover:not(:disabled) {
  background-color: #2c3e50;
  color: #ffffff;
}

.pagination button:disabled {
  background-color: #2c3e50;
  color: #7f8c8d;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .bonus-history {
    padding: 15px;
  }

  .bonus-summary {
    flex-direction: column;
  }

  .summary-item {
    margin-bottom: 10px;
  }
}
</style>

