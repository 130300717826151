import { createRouter, createWebHistory } from 'vue-router';

import MainHome from '@/views/MainHome.vue';
import MiniGame from '@/example/MiniGame.vue';
// import SportTable from '@/example/SportTable.vue';
import WorldSport from '@/components/sports/WorldSport.vue';
import SportTest from '@/components/SportTest.vue';


const routes = [
  {
    path: '/',
    name: 'MainHome',
    component: MainHome
  },
  {
    path: '/sport/game',
    name: 'WorldSport',
    component: WorldSport
  },
  // params: { agent_name:agent_name, category:category, prd_id:prd_id, game_id : game_id}});
  {
    path: '/minigame/:agent_name/:category/:prd_id/:game_id',
    name: 'minigame',
    component: MiniGame,
    props: true
  },
  


  // 테스트가 아직 덜 끝난 라우터들
  {
    path: '/sport/test',
    name: 'SportTest',
    component: SportTest,
    props: true // Enable passing route params as props to the component
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
