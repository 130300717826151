<template>
  <div class="withdraw-request">
    <div class="balance-summary">
      <div class="balance-item">
        <span class="balance-label">출금 가능 금액</span>
        <span class="balance-value">{{ formatNumber(store.state.balance) }}</span>
      </div>
    </div>
    <div class="withdraw-section">
      <div class="form-group">
        <label for="amount">출금 금액</label>
        <div class="bet-amount-container">
          <div class="cash-updown">
            <button class="down button" @click="decreaseAmount">-</button>
            <button class="up button" @click="increaseAmount">+</button>
          </div>
          <input 
            id="amount"
            v-model="formattedAmount"
            @input="updateAmount"
            type="text"
            inputmode="numeric"
            placeholder="금액 입력"
            class="input amount"
          >
          <button @click="setFullAmount" class="btn-full-amount">전액</button>
        </div>
      </div>
      <div class="form-group">
        <label for="withdrawPassword">출금 비밀번호</label>
        <input 
          id="withdrawPassword"
          v-model="withdrawPassword"
          type="password"
          class="form-control"
          placeholder="출금 비밀번호를 입력하세요"
        >
      </div>
      <div class="withdraw-info">
        <div class="info-item">
          <span class="info-label">출금 금액</span>
          <span class="info-value">{{ formatNumber(amount) }} 원</span>
        </div>
        <div class="info-item">
          <span class="info-label">출금 수수료</span>
          <span class="info-value">{{ formatNumber(withdrawalFee) }} 원</span>
        </div>
        <div class="info-item">
          <span class="info-label">실제 출금액</span>
          <span class="info-value">{{ formatNumber(actualWithdrawalAmount) }} 원</span>
        </div>
      </div>
      <button @click="submitWithdrawal" class="btn-submit" :disabled="!isFormValid">
        출금 신청
      </button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    </div>
    <div class="caution-box">
      <h3>출금 시 주의사항</h3>
      <ul>
        <li>출금은 1만원 단위로만 가능합니다.</li>
        <li>최소 출금 금액은 10,000원입니다.</li>
        <li>출금 신청 후 취소는 불가능합니다.</li>
        <li>출금 처리는 영업일 기준 최대 24시간 소요될 수 있습니다.</li>
        <li>은행 점검 시간에는 출금이 지연될 수 있습니다.</li>
      </ul>
    </div>
    <!-- 출금 내역 섹션 추가 -->
    <div class="withdraw-history">
      <button @click="toggleHistory" class="toggle-history">
        {{ showHistory ? '출금 내역 숨기기' : '출금 내역 보기' }}
      </button>
      <transition name="fade">
        <div v-if="showHistory" class="history-content">
          <h3>최근 출금 내역</h3>
          <ul>
            <li v-for="(item, index) in withdrawHistory" :key="index" class="history-item">
              <span class="history-date">{{ formatDate(item.date) }}</span>
              <span :class="['history-status', item.state]">{{ MONEY.STATE[item.state] }}</span>
              <span class="history-amount">-{{ formatNumber(item.amount) }}원</span>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { MONEY } from '@/composable/vars'

export default {
  name: 'MoneyWithdraw',
  setup() {
    const store = useStore();
    const amount = ref(0);
    const withdrawalFee = ref(0); // 예시 값
    const errorMessage = ref('');
    const successMessage = ref('');
    const withdrawPassword = ref('');
    const showHistory = ref(false);
    const withdrawHistory = ref([]);

    const fetchWithdrawHistory = async () => {
      const URL = "https://goldfish-app-cklru.ondigitalocean.app/api/monies?filters[type][$eq]=2&pagination[limit]=5&sort[0]=date_req:desc";
      const HEADERS = {
        "Content-Type": "application/json",
        Authorization: `bearer ${store.state.jwt}`,
      };
      try {
        const response = await axios({
          method: "GET",
          url: URL,
          headers: HEADERS,
        });
        withdrawHistory.value = response.data.data.map(element => ({
          date: element.attributes.date_req,
          state: element.attributes.state,
          amount: element.attributes.amount
        }));
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    const toggleHistory = () => {
      showHistory.value = !showHistory.value;
      if (showHistory.value) {
        fetchWithdrawHistory();
      } else {
        withdrawHistory.value = [];
      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${month}-${day} ${hours}:${minutes}`;
    };

    const formattedAmount = computed({
      get: () => amount.value.toLocaleString(),
      set: (value) => {
        let numValue = Number(value.replace(/[^0-9]/g, ''));
        amount.value = Math.floor(numValue / 10000) * 10000; // Round down to nearest 10,000
      }
    });

    const updateAmount = (event) => {
      const value = event.target.value.replace(/[^0-9]/g, '');
      amount.value = Math.floor(Number(value) / 10000) * 10000; // Round down to nearest 10,000
    };

    const increaseAmount = () => {
      if (amount.value + 10000 <= store.state.balance) {
        amount.value += 10000;
      }
    };

    const decreaseAmount = () => {
      if (amount.value >= 20000) {
        amount.value -= 10000;
      }
    };

    const setFullAmount = () => {
      amount.value = Math.floor(store.state.balance / 10000) * 10000; // Round down to nearest 10,000
    };

    const actualWithdrawalAmount = computed(() => {
      return amount.value - withdrawalFee.value;
    });

    const isFormValid = computed(() => {
      return amount.value >= 10000 && 
             amount.value <= store.state.balance && 
             withdrawPassword.value.trim() !== '';
    });

    const submitWithdrawal = async () => {
      if (!isFormValid.value) {
        errorMessage.value = '출금 금액이 유효하지 않거나 출금 비밀번호가 입력되지 않았습니다.';
        return;
      }

      const URL = "https://goldfish-app-cklru.ondigitalocean.app/api/monies";
      const HEADERS = {
        "Content-Type": "application/json",
        Authorization: `bearer ${store.state.jwt}`,
      };

      const OBJ = {
        data: {
          type: 2, // Assuming 1 is for withdrawal
          amount: amount.value,
          password_withdraw: withdrawPassword.value,
        },
      };

      try {
        const response = await axios({
          method: "POST",
          url: URL,
          headers: HEADERS,
          data: OBJ,
        });

        console.log('Withdrawal response:', response.data);
        successMessage.value = `${actualWithdrawalAmount.value.toLocaleString()}원 출금 신청이 완료되었습니다.`;
        errorMessage.value = '';

        // Reset form
        amount.value = 0;
        withdrawPassword.value = '';
      } catch (error) {
        console.error('Withdrawal error:', error.response?.data?.error || error.message);
        
        // Update this part to display the specific error message from the API
        if (error.response && error.response.data && error.response.data.error) {
          errorMessage.value = error.response.data.error.message || '출금 신청 중 오류가 발생했습니다. 다시 시도해 주세요.';
        } else {
          errorMessage.value = '출금 신청 중 오류가 발생했습니다. 다시 시도해 주세요.';
        }
        
        successMessage.value = '';
      }
    };

    const formatNumber = (num) => {
      return new Intl.NumberFormat('ko-KR').format(num);
    };

    return {
      store,
      MONEY,
      formattedAmount,
      updateAmount,
      increaseAmount,
      decreaseAmount,
      setFullAmount,
      isFormValid,
      submitWithdrawal,
      formatNumber,
      amount,
      withdrawalFee,
      actualWithdrawalAmount,
      errorMessage,
      successMessage,
      withdrawPassword,
      
      showHistory,
      withdrawHistory,
      toggleHistory,
      formatDate,
    };
  }
};
</script>


<style scoped>
.withdraw-request {
  max-width: 100vw;  /* 모바일 기본값 */
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
}

@media (min-width: 769px) {
  .withdraw-request {
    max-width: 500px;  /* PC 환경 */
  }
}

.balance-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.balance-item {
  text-align: center;
}

.balance-label {
  display: block;
  font-size: 0.9em;
  color: #b0b0b0;
  margin-bottom: 5px;
}

.balance-value {
  font-size: 1.1em;
  font-weight: bold;
  color: #ffffff;
}

.withdraw-section {
  background: transparent;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #d0d0d0;
}

.bet-amount-container {
  display: flex;
  align-items: stretch;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.cash-updown {
  display: flex;
}

.button {
  background: linear-gradient(145deg, #2c3e50, #34495e);
  border: none;
  color: white;
  padding: 0 12px;
  cursor: pointer;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.button:hover {
  background: linear-gradient(145deg, #34495e, #2c3e50);
}

.down {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.up {
  border-radius: 0;
}

.input.amount {
  flex-grow: 1;
  height: 40px;
  background-color: #2c3e50;
  border: none;
  color: white;
  text-align: right;
  padding: 0 10px;
  font-size: 16px;
}

.input.amount::placeholder {
  color: #7f8c8d;
}

.btn-full-amount {
  padding: 0 15px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-full-amount:hover {
  background: linear-gradient(145deg, #0056b3, #007bff);
}

.form-control {
  width: 100%;
  padding: 10px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1em;
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23FFFFFF%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px auto;
}

.withdraw-info {
  margin-top: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.info-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info-label {
  color: #b0b0b0;
}

.info-value {
  font-weight: bold;
  color: #ffffff;
}

.btn-submit {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-submit:hover:not(:disabled) {
  background: linear-gradient(145deg, #0056b3, #007bff);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn-submit:disabled {
  background: linear-gradient(145deg, #4a4a4a, #3a3a3a);
  cursor: not-allowed;
}

.caution-box {
  margin-top: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.caution-box h3 {
  color: #ffa500;
  margin-bottom: 10px;
}

.caution-box ul {
  padding-left: 20px;
  color: #b0b0b0;
}

.caution-box li {
  margin-bottom: 5px;
}

.error-message {
  color: #ff4d4d;
  margin-top: 10px;
  text-align: center;
}

.success-message {
  color: #4dff4d;
  margin-top: 10px;
  text-align: center;
}

/* 출금 내역 관련 새로운 스타일 */
.withdraw-history {
  margin-top: 20px;
}

.toggle-history {
  width: 100%;
  padding: 12px;
  background: linear-gradient(145deg, #2c3e50, #34495e);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.toggle-history:hover {
  background: linear-gradient(145deg, #34495e, #2c3e50);
}

.history-content {
  margin-top: 15px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.history-content h3 {
  color: #d0d0d0;
  margin-bottom: 10px;
}

.history-content ul {
  list-style-type: none;
  padding: 0;
}

.history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px 0;
  border-bottom: 1px solid #3a3a3a;
}

.history-date {
  color: #b0b0b0;
  font-size: 0.9em;
  width: 30%;
}

.history-status {
  font-size: 0.9em;
  width: 20%;
  text-align: center;
  padding: 2px 6px;
  border-radius: 4px;
}

.history-status.완료 {
  background-color: #4CAF50;
  color: white;
}

.history-status.처리중 {
  background-color: #FFC107;
  color: black;
}

.history-status.취소 {
  background-color: #F44336;
  color: white;
}

.history-amount {
  color: #ff4136;
  font-weight: bold;
  width: 30%;
  text-align: right;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, max-height 0.5s;
  max-height: 1000px;
  overflow: hidden;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  max-height: 0;
}


@media (max-width: 480px) {
  .withdraw-request {
    padding: 15px;
  }

  .input.amount, .btn-submit {
    font-size: 0.9em;
  }
}
</style>