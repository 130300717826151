<template>
  <div class="minigame-container" :key="componentKey">
    <div class="minigame-layout">
      <div class="main-content-wrapper">
        <div class="minigame-content">
          <div class="main-content">
            <div class="category-sm" v-if="gameDetails">
              <button v-for="(game,index) in gameDetails.game.game_list" :key="index" @click="clickSubcategory(game.game_id)" :class="{'selected': game.id === selectedSubcategoryId}"
              > 
                <img src="./../assets/minigame/ntry.logo.png" class="category-logo-img"><br>
                {{ game.game_name }}
              </button>
            </div>

            <div class="iframe-wrapper">
              <div class="iframe-container" :class="{ 'mobile': store.state.isMobile }">
                <div v-if="isLoading" class="loading-overlay">
                  <span class="loading-text">로딩 중...</span>
                </div>
                <iframe
                  ref="test"
                  v-if="gameDetails"
                  :src="IFRAME_MAPPING[1001][3].pc"
                  frameborder="0"
                  scrolling="no"
                  class="minigame-iframe"
                  @load="onIframeLoad"
                  @error="onIframeError"
                ></iframe>
                <div v-if="error" class="error-message">
                  {{ error }}
                </div>
              </div>
            </div>
      
            <div class="row">
              <div class="col-12" v-if="gameDetails">
                <div class="game-info">
                  <h6>(미니게임)   {{gameDetails.game.prd_name}}   {{findGameById(3).game_name}}   {{gameDetails.roundInfo.nextRound}}회차</h6>
                  <img src="./../assets/minigame/arrow.svg" class="arrow-img" @click="fetchMinigame()">
                </div>
              </div>
              <div class="col-lg-7 col-sm-12" v-if="gameDetails">
                <div class="bottom-section">
                  <div class="button-container">
                    <button
                      v-for="(list, index) in buttons"
                      :key="index"
                      @click="updateSelectedOptions(list)"
                      :class="[
                        'button-size-' + list.size,
                        { 'selected': isSelected(list) }
                      ]"
                    >
                      {{ list.text }}<br>
                      {{ list.odds }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-sm-12">
                <div class="cart-container" v-if="gameDetails">
                  <CartBasic
                    v-model:selectedOptions="selectedOptions"
                    :gametype="'minigame'"
                    :round="gameDetails.roundInfo.nextRound"
                    :gameDetails="gameDetails"
                    :game_id="game_id"
                  />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="betting-history" v-if="gameDetails">
      <div class="toggle-history-container">
      <button @click="toggleHistory" class="toggle-history">
        {{ showHistory ? '배팅내역 숨기기' : '배팅내역 보기' }}
      </button>
    </div>
    <transition name="fade">
      <div v-show="showHistory" class="history-content">
        <h3>최근 배팅 내역</h3>
        <ul>
          <li v-for="(item, index) in bettingHistory" :key="index" class="history-item">
            <span class="history-date">{{ formatDate(item.date_req) }}</span>
            <span class="history-date">{{ item.prd_name }}</span>
            <span class="history-date">{{ item.game_name }} - {{ item.round}}회차</span>
            <span class="history-date">{{ Object.keys(item.betting_detail)[0] }} - {{Object.values(item.betting_detail)[0]}}</span>
            <span class="history-amount">{{ formatNumber(item.betting_amount) }}원</span>
          </li>
        </ul>
      </div>
    </transition>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import CartBasic from '@/components/CartBasic.vue';

export default {
  name: 'MiniGame',
  components: { CartBasic },
  props: {
    agent_name: {type: String, required: false},
    category: {type: Number, required: false},
    prd_id: {type: Number, required: false},
    game_id: {type: Number, required: false},
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const componentKey = ref(0);
    const selectedOptions = ref([]);
    const isLoading = ref(true);
    const error = ref(null);

    const selectedSubcategoryId = ref(props.game_id)

    const gameDetails = ref(null)

    const sub_category = ref([]);
    const buttons = ref([]);

    const showHistory = ref(false);
    const bettingHistory = ref([]);

    const IFRAME_MAPPING = {
      // prd_id > game_id
      1001 : { // 엔트리
        3 : { // eos 3분
          mobile : 'https://ntry.com/scores/eos_powerball/3min/main.php?is_mobile=Y',
          pc : 'https://ntry.com/scores/eos_powerball/3min/main.php'
        },
        5 : {// eos 5분
          mobile : 'https://ntry.com/scores/eos_powerball/5min/main.php?is_mobile=Y',
          pc : 'https://ntry.com/scores/eos_powerball/5min/main.php'
        }
      }
    }

    const findGameById = (id) => {
      if (gameDetails.value && gameDetails.value.game && gameDetails.value.game.game_list) {
        return gameDetails.value.game.game_list.find(game => game.game_id == id);
      }
      return null;
    };
    const fetchBettingHistory = async () => {
      console.log(findGameById(props.game_id).game_name)
      const URL = `https://goldfish-app-cklru.ondigitalocean.app/api/bettings?pagination[limit]=5&filters[game_id][$eq]=${props.game_id}&filters[game_name][$containsi]=${findGameById(props.game_id).game_name}`;
      const HEADERS = {
        "Content-Type": "application/json",
        Authorization: `bearer ${store.state.jwt}`,
      };
      try {
        const response = await axios({
          method: "GET",
          url: URL,
          headers: HEADERS,
        });
        bettingHistory.value = response.data.data.map(element => ({
          date_req: element.attributes.date_req,
          betting_amount: element.attributes.betting_amount,
          round: element.attributes.round,
          game_name: element.attributes.game_name,
          prd_name : element.attributes.game.data.attributes.prd_name,
          betting_detail : element.attributes.betting_detail[0]
    
        }));
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };
    const toggleHistory = async () => {
      if (!showHistory.value) {
        await fetchBettingHistory();
      }
      showHistory.value = !showHistory.value;
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${month}-${day} ${hours}:${minutes}`;
    };

    const formatNumber = (num) => {
      return new Intl.NumberFormat('ko-KR').format(num);
    };

    const getStateText = (state) => {
      const stateMap = {
        'completed': '완료',
        'pending': '처리중',
        'cancelled': '취소'
      };
      return stateMap[state] || state;
    };

    
    
    
    // 모달 에러처리
    const updateScroll = () => {
      nextTick(() => {
        const mainContent = document.querySelector('.main-content-wrapper');
        if (mainContent) {
          mainContent.scrollTop = 0;
        }
        window.scrollTo(0, 0);
        
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
          document.body.style.overflow = '';
        }, 0);
      });
    };

    const forceRerender = () => {
      componentKey.value += 1;
      nextTick(() => {
        updateScroll();
      });
    };

    // 버튼 상태 확인
    const isSelected = (button) => {
      return selectedOptions.value.some(option => option._value === button._value);
    };


    const updateSelectedOptions = (newOption) => {
      const index = selectedOptions.value.findIndex(option => option._value === newOption._value);
      if (index !== -1) {
        // 이미 선택된 옵션이면 제거
        selectedOptions.value.splice(index, 1);
      } else {
        // 새로운 옵션이면 추가 (단, 기존 선택을 모두 제거하고 새로 추가)
        selectedOptions.value = [newOption];
      }
    };

    // 카테고리
    const clickSubcategory = (game_id) => {
      router.push({ name: 'minigame', params: { agent_name:props.agent_name, category:props.category, prd_id:props.prd_id, 'game_id' : game_id}});
    };
    
    // 아이프레임 관련
    const onIframeLoad = () => {
      isLoading.value = false;
    };

    const onIframeError = () => {
      error.value = "미니게임을 로드하는 중 오류가 발생했습니다.";
      isLoading.value = false;
    };

    

    const fetchMinigame = async () => {
      isLoading.value = true
      selectedOptions.value = []
      showHistory.value = false
      
      const URL = `https://goldfish-app-cklru.ondigitalocean.app/api/games/${props.agent_name}/auth/${props.category}/${props.prd_id}/${props.game_id}`;
      const HEADERS = {
        "Content-Type": "application/json",
        Authorization: `bearer ${store.state.jwt}`,
      };
      try {
        const response = await axios({
          method: "GET",
          url: URL,
          headers: HEADERS,
        });
      
      // game_list Off 처리
      response.data.game.game_list.forEach((element, index) => {
        if(element.game_status != "On" ){
          response.data.game.game_list.splice(index,1)
        }
      });

      if (!buttons.value.length == 0){
        buttons.value = []
      }
      // 버튼 배치
      response.data.betting_setting.forEach((element)=>{
        if(element['POWERBALL_SINGLE_ODDEVEN']){
          if(element['POWERBALL_SINGLE_ODDEVEN']['is_enable']){
        
            const gameRateSetting = response.data.game_rate_setting.filter(item => item.pool === 'POWERBALL_SINGLE_ODDEVEN');
            const rate_1 = gameRateSetting[0].rate_a[0]

            buttons.value.push({ text: '파워볼-홀', type: '파워볼 홀/짝', odds: rate_1, key: 'POWERBALL_SINGLE_ODDEVEN', value : 'ODD' ,_value:'POWERBALL_SINGLE_ODDEVEN_ODD',size:6})
            buttons.value.push({ text: '파워볼-짝', type: '파워볼 홀/짝', odds: rate_1, key: 'POWERBALL_SINGLE_ODDEVEN', value : 'EVEN',_value:'POWERBALL_SINGLE_ODDEVEN_EVEN',size:6})
          }
        }else if(element['POWERBALL_SINGLE_UNDEROVER']){
          if(element['POWERBALL_SINGLE_UNDEROVER']['is_enable']){
        
            const gameRateSetting = response.data.game_rate_setting.filter(item => item.pool === 'POWERBALL_SINGLE_UNDEROVER');
            const rate_1 = gameRateSetting[0].rate_a[0]

            buttons.value.push({ text: '파워볼-오버', type: '파워볼 언/오버', odds: rate_1, key: 'POWERBALL_SINGLE_UNDEROVER',value : 'OVER' ,_value:'POWERBALL_SINGLE_UNDEROVER_OVER',size:6})
            buttons.value.push({ text: '파워볼-언더', type: '파워볼 언/오버', odds: rate_1, key: 'POWERBALL_SINGLE_UNDEROVER',value : 'UNDER',_value:'POWERBALL_SINGLE_UNDEROVER_UNDER',size:6})
          }
        }else if(element['NORMALBALL_SINGLE_ODDEVEN']){
          if(element['NORMALBALL_SINGLE_ODDEVEN']['is_enable']){
        
            const gameRateSetting = response.data.game_rate_setting.filter(item => item.pool === 'NORMALBALL_SINGLE_ODDEVEN');
            const rate_1 = gameRateSetting[0].rate_a[0]

            buttons.value.push({ text: '일반볼-홀', type: '일반볼 홀/짝', odds: rate_1, key: 'NORMALBALL_SINGLE_ODDEVEN', value : 'ODD' ,_value:'NORMALBALL_SINGLE_ODDEVEN_ODD',size:6},)
            buttons.value.push({ text: '일반볼-짝', type: '일반볼 홀/짝', odds: rate_1, key: 'NORMALBALL_SINGLE_ODDEVEN', value : 'EVEN',_value:'NORMALBALL_SINGLE_ODDEVEN_EVEN',size:6},)
          }
        }else if(element['NORMALBALL_SINGLE_UNDEROVER']){
          if(element['NORMALBALL_SINGLE_UNDEROVER']['is_enable']){
        
            const gameRateSetting = response.data.game_rate_setting.filter(item => item.pool === 'NORMALBALL_SINGLE_UNDEROVER');
            const rate_1 = gameRateSetting[0].rate_a[0]

            buttons.value.push({ text: '일반볼-오버', type: '일반볼 언/오버', odds: rate_1, key: 'NORMALBALL_SINGLE_UNDEROVER', value : 'OVER' ,_value:'NORMALBALL_SINGLE_UNDEROVER_OVER',size:6},)
            buttons.value.push({ text: '일반볼-언더', type: '일반볼 언/오버', odds: rate_1, key: 'NORMALBALL_SINGLE_UNDEROVER', value : 'UNDER',_value:'NORMALBALL_SINGLE_UNDEROVER_UNDER',size:6},)
          }
        }else if(element['NORMALBALL_SINGLE_LMS']){
          if(element['NORMALBALL_SINGLE_LMS']['is_enable']){
        
            const gameRateSetting = response.data.game_rate_setting.filter(item => item.pool === 'NORMALBALL_SINGLE_LMS');
            // const rate_1 = gameRateSetting[0].rate_a[0]

            buttons.value.push({ text: '일반볼-대', type: '일반볼 대/중/소', odds: gameRateSetting[0].rate_a[0], key: 'NORMALBALL_SINGLE_LMS', value : 'LARGE' ,_value:'NORMALBALL_SINGLE_LMS_LG',size:12},)
            buttons.value.push({ text: '일반볼-중', type: '일반볼 대/중/소', odds: gameRateSetting[0].rate_a[1], key: 'NORMALBALL_SINGLE_LMS', value : 'MEDIUM',_value:'NORMALBALL_SINGLE_LMS_MD',size:12},)
            buttons.value.push({ text: '일반볼-소', type: '일반볼 대/중/소', odds: gameRateSetting[0].rate_a[0], key: 'NORMALBALL_SINGLE_LMS', value : 'SMALL',_value:'NORMALBALL_SINGLE_LMS_SM',size:12},)
          }
        }
      })

      store.commit('setBalance',response.data.gamemoney)

      gameDetails.value = response.data
      isLoading.value = false


      } catch (error) {
        console.log(error);
      }
    } 

    onMounted(() => {
      fetchMinigame()
      updateScroll();
      window.addEventListener('resize', updateScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateScroll);
    });

    watch(() => route.fullPath, (newPath, oldPath) => {
      if (newPath !== oldPath) {
        fetchMinigame()
        forceRerender();
      }
    });

    router.beforeEach((to, from, next) => {
      if (to.name === 'MiniGame') {
        forceRerender();
      }
      next();
    });

    return {
      store,
      selectedOptions,
      updateSelectedOptions,
      isLoading,
      error,
      onIframeLoad,
      onIframeError,
      sub_category,
      clickSubcategory,
      buttons,

      isSelected,
      componentKey,
      updateScroll,
      forceRerender,
      gameDetails,
      selectedSubcategoryId,
      findGameById,
      fetchMinigame,

      IFRAME_MAPPING,
      showHistory,
      toggleHistory,
      bettingHistory,
      formatDate,
      formatNumber,
      getStateText,
    };
  }
};
</script>



<style scoped>
.minigame-container {
  min-height: 100vh;
  background: linear-gradient(to right, #2e272a, #000000);
  color: white;
  width: 100%;
  overflow-x: hidden;
  padding: 20px;
  box-sizing: border-box; /* 추가 */
}

.minigame-layout {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%; /* 추가 */
}

.main-content-wrapper {
  flex: 1;
  overflow-y: auto;
}

.minigame-content {
  flex-grow: 1;
}

.main-content {
  padding: 30px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
}

.game-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
  border: 2px solid #444;
  padding: 10px;
  font-size: 1em; 
  /* background: #1c1618; */
}

.game-info h6 {
  margin: 0;
  font-size: 0.9em;
  text-align: left;
}

.arrow-img {
  width: 20px;
  cursor: pointer;
}

.category-sm {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
  justify-content: center;
}

.category-sm button {
  padding: 10px 20px;
  background-color: #333333;
  border: 1px solid #444444;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 15px;
  white-space: nowrap;
}

.category-sm button:hover {
  background-color: #444444;
}

.category-sm button.selected {
  background-color: #007bff;
  border-color: #0056b3;
}
.category-logo-img{
  width:17px;
}

.iframe-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.iframe-container {
  position: relative;
  width: 100%;
  max-width: 860px;
  height: 0;
  padding-bottom: 54.4%;
  overflow: hidden;
}

.iframe-container.mobile {
  padding-bottom: 111.67%; 
  
}

.minigame-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2em;
}

.error-message {
  color: #ff6b6b;
  text-align: center;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px; /* 수정 */
  margin-left: -10px; /* 수정 */
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding-right: 20px;
  padding-left: 20px;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  padding-right: 20px;
  padding-left: 20px;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  padding-right: 20px;
  padding-left: 20px;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2열 레이아웃 */
  gap: 5px;
}

.button-size-6 {
  grid-column: span 1; /* 1열 차지 (2열 중 1열) */
}

.button-size-12 {
  grid-column: span 2; /* 2열 모두 차지 */
}

.button-container button {
  padding: 10px 5px;
  background-color: #333333;
  border: 1px solid #444444;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  text-align: center;
  width: 100%; /* 버튼이 컨테이너 너비를 꽉 채우도록 설정 */
}


.button-container button:hover {
  background-color: #444444;
}

.button-container button.selected {
  background-color: #253152;
  border-color: #0056b3;
}

.cart-container {
  background-color: #2a2a2a;
  padding: 25px;
  border-radius: 8px;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
}

.betting-submit {
  margin-top: 25px;
  padding: 18px;
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.2);
}

.betting-submit:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 123, 255, 0.3);
}

.betting-submit:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 123, 255, 0.3)
}


.betting-history {
  margin-top: 20px;
  max-width: 1200px;  /* 최대 너비 설정 */
  margin-left: auto;
  margin-right: auto;
}

.toggle-history-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.toggle-history {
  width: 40%;
  padding: 12px;
  background: linear-gradient(145deg, #2c3e50, #34495e);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.toggle-history:hover {
  background: linear-gradient(145deg, #34495e, #2c3e50);
}

.history-content {
  margin-top: 15px;
  padding: 15px;
  background: linear-gradient(145deg, #1e1e1e, #252525);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.history-content h3 {
  color: #d0d0d0;
  margin-bottom: 15px;
  font-size: 1.1em;
}

.history-content ul {
  list-style-type: none;
  padding: 0;
}

.history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px 0;
  border-bottom: 1px solid #3a3a3a;
}

.history-date {
  color: #b0b0b0;
  font-size: 0.9em;
  width: 30%;
}

.history-status {
  font-size: 0.9em;
  width: 20%;
  text-align: center;
  padding: 2px 6px;
  border-radius: 4px;
}

.history-status.completed {
  color: #4dff4d;
  background-color: rgba(77, 255, 77, 0.1);
}

.history-status.pending {
  color: #ffa500;
  background-color: rgba(255, 165, 0, 0.1);
}

.history-status.cancelled {
  color: #ff4d4d;
  background-color: rgba(255, 77, 77, 0.1);
}

.history-amount {
  color: #007bff;
  font-weight: bold;
  width: 30%;
  text-align: right;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.5s ease;
  max-height: 1000px;
  opacity: 1;
}

.fade-enter, .fade-leave-to {
  max-height: 0;
  opacity: 0;
}

@media (max-width: 1024px) {
  .col-9, .col-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .cart-container {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .button-container {
    grid-template-columns: repeat(2, 1fr); /* 모바일에서는 2열 레이아웃 */
  }

  .button-container button {
    padding: 15px 10px;
    font-size: 14px;
  }

  .button-size-12 {
    grid-column: span 2; /* 큰 사이즈는 모바일에서도 2열 차지 */
  }

  .minigame-container {
    padding: 10px;
  }

  .main-content {
    padding: 20px;
  }

  .game-info h6 {
    font-size: 1em;
  }

  .category-sm {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
    justify-content: flex-start;
  }

  .category-sm button {
    flex: 0 0 auto;
    font-size: 14px;
    padding: 8px 16px;
  }

  .iframe-wrapper {
    height: 515px;
  }

  .button-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .button-container button {
    padding: 15px 10px;
    font-size: 14px;
  }

  .betting-submit {
    font-size: 16px;
    padding: 15px;
  }
  .iframe-container {
    max-width: 300px;
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .minigame-container {
    padding: 5px;
  }

  .main-content {
    padding: 15px;
  }

  .game-info h6 {
    font-size: 0.9em;
  }

  .category-sm button {
    font-size: 12px;
    padding: 6px 12px;
  }

  .iframe-wrapper {
    /* width: 100vw;
     position: absolute; */
    height: 400px;
  }

  .button-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .button-container button {
    padding: 12px 8px;
    font-size: 13px;
  }

  .betting-submit {
    font-size: 15px;
    padding: 12px;
  }

  .history-item {
    font-size: 0.9em;
  }
  
  .history-date {
    width: 35%;
  }
  
  .history-status {
    width: 25%;
  }
  
  .history-amount {
    width: 40%;
  }
}
</style>






