<template>
  <div class="coupon-history">
    <h2 class="text-center mb-4">쿠폰 내역</h2>
    <div class="coupon-summary">
      <div class="summary-item">
        <span class="summary-label">총 쿠폰 수</span>
        <span class="summary-value">{{ totalCoupons }}</span>
      </div>
      <div class="summary-item">
        <span class="summary-label">사용 가능한 쿠폰</span>
        <span class="summary-value">{{ availableCoupons }}</span>
      </div>
    </div>
    <div class="coupon-table-container">
      <table class="coupon-table">
        <thead>
          <tr>
            <th>발급일</th>
            <th>쿠폰명</th>
            <th>할인율/금액</th>
            <th>만료일</th>
            <th>상태</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="coupon in coupons" :key="coupon.id">
            <td>{{ formatDate(coupon.issueDate) }}</td>
            <td>{{ coupon.name }}</td>
            <td>{{ formatDiscount(coupon.discount, coupon.discountType) }}</td>
            <td>{{ formatDate(coupon.expiryDate) }}</td>
            <td>
              <span :class="['status', coupon.status]">{{ coupon.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">&lt; 이전</button>
      <span>{{ currentPage }} / {{ totalPages }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">다음 &gt;</button>
    </div>
  </div>

</template>

<script>
import { ref, computed, onMounted} from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';


export default {
  name: 'CouponList',
  components: {},
  setup() {
    const store = useStore()
    const totalCoupons = ref(15);
    const availableCoupons = ref(5);
    const currentPage = ref(1);
    const itemsPerPage = 10;

    const coupons = ref([
      { id: 1, issueDate: new Date('2023-07-01'), name: '신규 가입 쿠폰', discount: 10, discountType: 'percent', expiryDate: new Date('2023-08-01'), status: '사용 가능' },
      { id: 2, issueDate: new Date('2023-07-15'), name: '여름 이벤트 쿠폰', discount: 5000, discountType: 'amount', expiryDate: new Date('2023-08-15'), status: '사용 완료' },
      { id: 3, issueDate: new Date('2023-08-01'), name: '생일 축하 쿠폰', discount: 15, discountType: 'percent', expiryDate: new Date('2023-09-01'), status: '만료됨' },
      // ... 더 많은 쿠폰 내역
    ]);

    const totalPages = computed(() => Math.ceil(coupons.value.length / itemsPerPage));

    const paginatedCoupons = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      return coupons.value.slice(start, end);
    });

    const prevPage = () => {
      if (currentPage.value > 1) currentPage.value--;
    };

    const nextPage = () => {
      if (currentPage.value < totalPages.value) currentPage.value++;
    };

    const formatDate = (date) => {
      const d = new Date(date);
      const year = d.getFullYear().toString().substr(-2);
      const month = (d.getMonth() + 1).toString().padStart(2, '0');
      const day = d.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const formatDiscount = (discount, type) => {
      return type === 'percent' ? `${discount}%` : `${discount.toLocaleString()}원`;
    };

    const fetchCouponList = async () => {
      // isLoading.value = true;
      try {
        const response = await axios.get(`https://goldfish-app-cklru.ondigitalocean.app/api/coupons`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `bearer ${store.state.jwt}`
          }
        });
        console.log('coupon info:', response.data);
        // Here you can update the store or local state with the fetched data
        // For example: store.commit('UPDATE_USER_INFO', response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        // isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchCouponList()
    })

    return {
      totalCoupons,
      availableCoupons,
      coupons: paginatedCoupons,
      currentPage,
      totalPages,
      prevPage,
      nextPage,
      formatDate,
      formatDiscount,
    };
  }
};
</script>

<style scoped>
.coupon-history {
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  color: #e0e0e0;
}

.coupon-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #0f0f0f, #1a1a1a);
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

.summary-item {
  text-align: center;
}

.summary-label {
  display: block;
  font-size: 0.9em;
  color: #a0a0a0;
  margin-bottom: 5px;
}

.summary-value {
  font-size: 1.2em;
  font-weight: bold;
  color: #f0f0f0;
}

.coupon-table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.coupon-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.coupon-table th,
.coupon-table td {
  padding: 12px;
  text-align: left;
}

.coupon-table th {
  background-color: #1c2833;
  color: #f0f0f0;
  font-weight: bold;
}

.coupon-table tr {
  background-color: #1c2833;
}

.coupon-table tr:hover {
  background-color: #253649;
}

.coupon-table td {
  color: #d0d0d0;
}

.status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: bold;
}

.status.사용가능 {
  background-color: #1a5928;
  color: #a0e8af;
}

.status.사용완료 {
  background-color: #1a4c68;
  color: #a0d8e8;
}

.status.만료됨 {
  background-color: #681a1a;
  color: #e8a0a0;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 5px 10px;
  margin: 0 10px;
  background-color: #1c2833;
  color: #d0d0d0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.pagination button:hover:not(:disabled) {
  background-color: #2c3e50;
  color: #ffffff;
}

.pagination button:disabled {
  background-color: #2c3e50;
  color: #7f8c8d;
  cursor: not-allowed;
}


@media (max-width: 768px) {
  .coupon-history {
    padding: 10px;
  }

  .coupon-summary {
    flex-direction: column;
  }

  .summary-item {
    margin-bottom: 10px;
  }
}
</style>