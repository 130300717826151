<template>
  <div :class="modalClass" id="infoModal" tabindex="-1" aria-labelledby="infoModalLabel" aria-hidden="true">
    <div :class="dialogClass">
      <div class="modal-content bg-dark text-light">
        <div class="modal-header border-bottom border-secondary">
         <!-- Mobile: Swiper for tabs -->
           <swiper
            v-if="isMobile"
            :slides-per-view="4.2"
            :space-between="10"
            :modules="modules"
            :pagination="{ clickable: true }"
            class="mySwiper"
          >
            <swiper-slide v-for="tab in tabs" :key="tab.value">
              <button 
                class="nav-link" 
                :class="{ active: localSelectedTab === tab.value }" 
                @click="selectTab(tab.value)"
              >
                {{ tab.name }}
              </button>
            </swiper-slide>
          </swiper>
          
          <!-- Desktop: Regular tabs -->
          <ul v-else class="nav nav-tabs">
            <li class="nav-item" v-for="tab in tabs" :key="tab.value">
              <button 
                class="nav-link" 
                :class="{ active: localSelectedTab === tab.value }" 
                @click="selectTab(tab.value)"
              >
                {{ tab.name }}
              </button>
            </li>
          </ul>
          <button type="button" class="btn-close btn-close-white" style="margin-left:8px;" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-light" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-else>
            <div v-if="localSelectedTab === 'exchange'" class="info-content">
              <MoneyExchange />
            </div>
            <div v-else-if="localSelectedTab === 'deposit'" class="info-content">
              <MoneyDeposit />
            </div>
            <div v-else-if="localSelectedTab === 'withdraw'" class="info-content">
              <MoneyWithdraw />
            </div>
            <div v-else-if="localSelectedTab === 'point'" class="info-content">
              <PointList />
            </div>
            <div v-else-if="localSelectedTab === 'coupons'" class="info-content">
              <CouponList />
            </div>
            <div v-else-if="localSelectedTab === 'passwordchange'" class="info-content">
              <PasswordChange />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

import MoneyExchange from '@/components/community/MoneyExchange.vue';
import MoneyDeposit from '@/components/community/MoneyDeposit.vue';
import MoneyWithdraw from '@/components/community/MoneyWithdraw.vue';
import PointList from '@/components/community/PointList.vue';
import CouponList from '@/components/community/CouponList.vue';
import PasswordChange from '@/components/community/PasswordChange.vue';

export default {
  name: 'InfoModal',
  components: {
    MoneyExchange,
    MoneyDeposit,
    MoneyWithdraw,
    PointList,
    CouponList,
    PasswordChange,
    Swiper,
    SwiperSlide,
  },
  props: {
    selectedTab: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    let modalInstance = null;

    const tabs = ref([
      { name: '머니환전/이동', value: 'exchange' },
      { name: '입금신청', value: 'deposit' },
      { name: '출금신청', value: 'withdraw' },
      { name: '포인트내역', value: 'point' },
      { name: '쿠폰내역', value: 'coupons' },
      { name: '비밀번호변경', value: 'passwordchange' },
    ]);

    const isLoading = ref(false);
    const localSelectedTab = computed(() => store.state.infoModalTab);
    const isMobile = computed(() => store.state.isMobile);
    const modalClass = computed(() => isMobile.value ? 'modal fade full-screen-modal' : 'modal fade');
    const dialogClass = computed(() => isMobile.value ? 'modal-dialog modal-fullscreen' : 'modal-dialog modal-md');

    const modules = [Navigation];
    const navigation = computed(() => !isMobile.value);
    const slidesPerView = computed(() => isMobile.value ? 3 : 6);

    const selectTab = (tab) => {
      store.commit('SET_INFO_MODAL_TAB', tab);
    };

    const fetchInfo = async () => {
      isLoading.value = true;
      try {
        const response = await axios.get(`https://goldfish-app-cklru.ondigitalocean.app/api/userinfos/${store.state.user.id}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `bearer ${store.state.jwt}`
          }
        });
        console.log('User info:', response.data);
        // Here you can update the store or local state with the fetched data
        // For example: store.commit('UPDATE_USER_INFO', response.data);
      } catch (error) {
        console.error('Error fetching user info:', error);
        // Handle error (e.g., show error message to user)
      } finally {
        isLoading.value = false;
      }
    };

    watch(() => store.state.userInfo, (newUserInfo) => {
      if (newUserInfo) {
        isLoading.value = false;
      }
    }, { immediate: true });

    onMounted(() => {
      const modalElement = document.getElementById('infoModal');
      if (modalElement) {
        modalInstance = new window.bootstrap.Modal(modalElement, {
          backdrop: 'static',
          keyboard: true
        });

        modalElement.addEventListener('hidden.bs.modal', () => {
          store.commit('SET_INFO_MODAL_OPEN', false);
        });

        modalElement.addEventListener('shown.bs.modal', () => {
          // fetchInfo(); // Call fetchInfo when modal is opened
        });
      }
      
      watch(() => store.state.infoModalOpen, (newValue) => {
        if (newValue && modalInstance) {
          modalInstance.show();
        } else if (modalInstance) {
          modalInstance.hide();
        }
      }, { immediate: true });
    });

    onUnmounted(() => {
      if (modalInstance) {
        modalInstance.dispose();
      }
      const modalElement = document.getElementById('infoModal');
      if (modalElement) {
        modalElement.removeEventListener('hidden.bs.modal', () => {
          store.commit('SET_INFO_MODAL_OPEN', false);
        });
        modalElement.removeEventListener('shown.bs.modal', () => {
          fetchInfo();
        });
      }
    });

    const closeModal = () => {
      if (modalInstance) {
        modalInstance.hide();
      }
      const modalElement = document.getElementById('infoModal');
      if (modalElement) {
        store.commit('SET_INFO_MODAL_OPEN', false);
      }
    };

    return {
      store,
      tabs,
      localSelectedTab,
      selectTab,
      isLoading,
      isMobile,
      modalClass,
      dialogClass,
      closeModal,
      fetchInfo,
      modules,
      navigation,
      slidesPerView,
    };
  },
};
</script>

<style scoped>
.modal-md {
  max-width: 60%;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  color: #fff;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  color: #fff;
  background-color: transparent;
  border-bottom: 2px solid #007bff;
}

.info-content {
  /* padding: 1rem; */
}

.full-screen-modal .modal-dialog {
  margin: 0;
  padding: 0;
}

.full-screen-modal .modal-content {
  min-height: 100vh;
  border: none;
  border-radius: 0;
}

@media (max-width: 768px) {
  .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .modal-md {
    max-width: 100%;
  }
}
</style>