<template>
  <div class="login-form">
    <!-- <h2 class="form-title">로그인</h2> -->
    <form @submit.prevent="handleSubmit" class="form-content">
      <div class="form-group">
        <label for="identifier">아이디</label>
        <input
          id="identifier"
          v-model="identifier"
          type="text"
          class="form-control"
          placeholder="아이디를 입력하세요"
          required
        >
      </div>
      <div class="form-group">
        <label for="password">비밀번호</label>
        <input
          id="password"
          v-model="password"
          type="password"
          class="form-control"
          placeholder="비밀번호를 입력하세요"
          required
        >
      </div>
      <!-- <div class="form-options">
        <div class="remember-me">
          <input
            id="remember-me"
            type="checkbox"
            v-model="rememberMe"
          >
          <label for="remember-me">로그인 유지</label>
        </div>
        <a href="#" class="forgot-password">비밀번호 찾기</a>
      </div> -->
      <button type="submit" class="btn-submit" :disabled="!isFormValid">
        로그인
      </button>
    </form>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export default {
  name: 'LoginForm',
  setup() {
    const store = useStore();
    const identifier = ref('');
    const password = ref('');
    const rememberMe = ref(false);
    const errorMessage = ref('');

    const isFormValid = computed(() => {
      return identifier.value.trim() !== '' && password.value.trim() !== '';
    });

    const handleSubmit = () => {
      if (!isFormValid.value) {
        errorMessage.value = '아이디와 비밀번호를 모두 입력해주세요.';
        return;
      }

      store.dispatch('login', {
        identifier: identifier.value,
        password: password.value,
        rememberMe: rememberMe.value
      }).catch(error => {
        console.log(error)
        errorMessage.value = '로그인에 실패했습니다. 아이디와 비밀번호를 확인해주세요.';
      });
    };

    return {
      identifier,
      password,
      rememberMe,
      handleSubmit,
      isFormValid,
      errorMessage
    };
  }
};
</script>

<style scoped>
.login-form {
  max-width: 100vw;
  margin: 0 auto;
  padding: 20px;
  background: linear-gradient(145deg, #2a2a2a, #1a1a1a);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  color: white;
}

@media (min-width: 769px) {
  .login-form {
    max-width: 400px;
  }
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  color: #ffffff;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #d0d0d0;
}

.form-control {
  width: 100%;
  padding: 10px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1em;
}

.form-control::placeholder {
  color: #7f8c8d;
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input {
  margin-right: 5px;
}

.forgot-password {
  color: #007bff;
  text-decoration: none;
}

.btn-submit {
  width: 100%;
  padding: 12px;
  background: linear-gradient(145deg, #007bff, #0056b3);
  color: #ffffff;
  border: none;
  border-radius: 6px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.btn-submit:hover:not(:disabled) {
  background: linear-gradient(145deg, #0056b3, #007bff);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn-submit:disabled {
  background: linear-gradient(145deg, #4a4a4a, #3a3a3a);
  cursor: not-allowed;
}

.error-message {
  color: #ff4d4d;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 480px) {
  .login-form {
    padding: 15px;
  }

  .btn-submit {
    font-size: 0.9em;
  }
}
</style>